import React from 'react'
import * as S from "../../styles/ezev-td-styles/LandingPage-styles";

function GroupDownloadPdf(props){
  
  function handleButtonClick() {
    props.openModal()
    setTimeout(() => {
      props.setDisplayPdfTooltip(false)
    }, [5000])
  }

  return(
    <S.PdfDownloadContainer>
      {props.displayPdfTooltip &&
      <S.PdfMessageToolTip>
        PDF is compiling and will download when ready. You may navigate through the rest of the product during this time.
      </S.PdfMessageToolTip>
      }
      <S.TableDownloadLink
        onClick={handleButtonClick}
        href={props.link}
        disabled = {props.dataLoading}
        data-testid="pdfDownload-testid"
      >
        Download PDF
      </S.TableDownloadLink>
    </S.PdfDownloadContainer>
  );
}

export default GroupDownloadPdf;
