import React from 'react';
import { Link } from "react-router-dom";
import * as S from "../../styles/ezev-td-styles/Links-styles"

export default function LinkTable() {
  return (
    <div className="ezev-link-wrapper">
      < Link className="ezev-link" to={{ pathname: "/ezev-td/vehicle-results" }}>Table</Link>
    </div>
  )
}

export function LinkBackToTable() {
  return (
    <div className="ezev-link-wrapper">
      < Link className="ezev-link" to={{ pathname: "/ezev-td/vehicle-results" }}>Back</Link>

    </div>
  )
}

export function LinkHome(props) {
  return (
    <div className={`ezev-link-wrapper ${props.addClass}`}>
      <Link className="ezev-link" to={{ pathname: "/ezev-td/landing" }}>Home</Link>
    </div>
  )
}

export function LinkSelectGroupLanding(props) {
  return (
    <div className={`ezev-link-wrapper`} onClick={props.onClick}>
      < Link className="ezev-link" to={{ pathname: "/ezev-td/landing" }} >Select</Link>
    </div>
  )
}

export function LinkBack() {
  return (
    <div className="ezev-link-wrapper">
      < Link className="ezev-link" to={{ pathname: "/ezev-td/landing" }}>Back</Link>
    </div>
  )
}

export function LinkSingleVcl() {
  return (
    <div className="ezev-link-wrapper">
      < Link className="ezev-link" to={{ pathname: "/ezev-td/vehicle" }}>Back</Link>
    </div>
  )
}


export function LinkAssumptions() {
  return (
    <div className="ezev-link-wrapper ezev-assumptions-button">
      <Link
        className="ezev-link"
        data-testid="assumptionsView-testid"
        to={{ pathname: "/ezev-td/assumptions" }}
      >
        View Assumptions
      </Link>
    </div>
  )
}

export const LinkShowVehicles = () => (
  <S.ShowVehiclesLink
    to={"/ezev-td/vehicle-results"}
    className="ezev-button-2 ezev-link-wrapper ezev-button-font-2"
    data-testid="showVehiclesButton-testid"
  >
    Show Vehicles
  </S.ShowVehiclesLink>
)

export function LinkEditAssumptions() {
  // for admins, redirects to dashboard admin cog, settings/assumptions edit
  return (
    <div className="ezev-link-wrapper ezev-assumptions-button">
      <Link
        className="ezev-link"
        data-testid="assumptionsEdit-testid"
        to={{
          pathname: "/admin/settings",
          state: { redirectFrom: "/ezev-td/group-select" }
        }}
      >
        Edit Assumptions
      </Link>
    </div>
  )
}

export function LinkLocationEditTool() {

  return (
      <Link to={"/location-edit-tool"}>
        Location Edit Tool
      </Link>
  )
}
