import styled from "styled-components/macro";
import * as colors from "./ColorScheme";
import { Link } from "react-router-dom";

type ToolTipWrapperProps = {
  cursorCoords: {x: number, y: number};
}

export const DisplayLayout = styled.div`
display: flex;
flex-direction: column;
max-width: 1200px;
margin: 0 auto;
`

export const TableTitle = styled.h1`
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 30px;
color: ${colors.ezio_dark_grey};
margin-bottom: 10px;
`

export const TableSubtitle = styled.span`
font-family: "Arimo", "Roboto";
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
color: ${colors.ezio_medium_grey};
margin-bottom: 20px;
`

export const LocationLink = styled(Link)`
color: inherit;
text-decoration: underline;
&:hover {
  color: ${colors.ezio_dark_green};
}
`

export const TableParkingToolTipWrapper = styled.div<ToolTipWrapperProps>`
    padding: 8px;
    border: 1px solid #979a9e;
    box-shadow: 2px 2px 4px #47484a;
    border-radius: 5px;
    width: 300px;
    z-index: 1;
    background-color: white;
    font-size: 14px;
    display: inline-block;
    color: #47484a;
    position: absolute;
    top: -75px;
    right: 10px;
`;

export const ToolTipWrapper = styled.div<ToolTipWrapperProps>`
    padding: 8px;
    border: 1px solid #979a9e;
    box-shadow: 2px 2px 4px #47484a;
    border-radius: 5px;
    width: 300px;
    z-index: 1;
    background-color: white;
    font-size: 14px;
    display: inline-block;
    color: #47484a;
    position: absolute;
    ${({ cursorCoords }:  ToolTipWrapperProps) => {
        return `left: ${cursorCoords.x}px;top: ${cursorCoords.y}px`
    }}
`;

export const ToolTipText = styled.p`
    margin: 0;
`
