import React, { useState, useEffect, Suspense } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import HeaderSingleVcl from './HeaderSingleVcl';
import { processApiResponse } from './utils/ConformUnits';
import { calcEffectiveBattery } from './HelperFunctions';
import Loading from './loading';

// code-split imports
const SingleVclPage = React.lazy(() => import("./SingleVclPage"));

const SingleVclController = (props) => {
  const [vclResults, setVclResults] = useState(null);
  const [candidateResults, setCandidateResults] = useState(null);
  const [parkingData, setParkingData] = useState(null);
  const [vclSummaryData, setVclSummaryData] = useState(null);

  const history = useHistory();
  const location = useLocation();
  const { vin } = useParams();

  useEffect(() => {
    props.getAnalyticsProcessingState(false);
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activity]);

  const getData = () => {
    const vinFromPath = vin;

    // Check if location.state exists; if not, use the VIN from the pathname
    if (!location.state && vinFromPath) {
      location.state = { vcl: vinFromPath };  // Set location.state manually if not present
    }

    if (!location.state || !props.activity) return;

    if (setVclData() && props.parkingData) {
      getCandidateData();
      getParkingData();
    }
  };

  const setVclData = () => {
    // TODO: Update this to be in swt-ezev-td.js. Should just select correct vehicle and pass to SingleVclController.js
    const vehicleVin = location.state.vcl;
    const vcl = props.activity.find(v => v.vin === vehicleVin);

    if (vcl) {
      setVclResults(vcl);
      fetchVclDailyActivity(vcl);  // Fetch daily activity if the vehicle is found
      return true;
    } else {
      console.error("Vehicle not found. Redirecting to landing page...");
      history.push('/');
      return false;
    }
  };

  // Simplified data fetch and processing
  const getCandidateData = () => {
    const vcl = location.state.vcl;
    const url = `${props.apiURL}getezevcandidateresults`;
    const queries = `?ident=${props.dbName}&vin=${vcl}`;

    fetch(`${url}${queries}`, {
      headers: { 'Authorization': `Bearer ${props.user.token}` }
    })
      .then(res => res.json())
      .then(data => {
        const candidatesData = data?.data?.[0]?.json || {};  
        const { bevs = [], phevs = [] } = candidatesData;

        // Process each candidate list and map their upfits
        [bevs, phevs].forEach((candidates) => {
          candidates.forEach(d => {
            const cand = props.candidates.find(c => c.id === d.id);
            if (cand?.upfits) d.upfits = cand.upfits;
            processApiResponse(props.user.userSettings, d);
          });
        });

        setCandidateResults(candidatesData);
      })
      .catch(error => console.error("Error fetching candidate data: ", error));
  };

  const getParkingData = () => {
    const vehicleVin = location.state.vcl;
    setParkingData(props.parkingData[vehicleVin] || []);
  };

  const fetchVclDailyActivity = (vcl) => {
    const url = props.apiURL;
    const endDate = new Date(vcl.observed_max_ts.date).toISOString().split("T")[0];
    const startDate = new Date(vcl.observed_min_ts.date).toISOString().split("T")[0];
    const singleVclUrl = `${url}getEnergyGraphData?`;
    const queries = `clientId=${props.settings.db}&vin=${vcl.vin}&start=${startDate}&stop=${endDate}`;

    fetch(`${singleVclUrl}${queries}`, {
      headers: { Authorization: `Bearer ${props.user.token}` },
    })
      .then(res => res.json())
      .then(data => {
        const sortedData = data.data?.sort((a, b) => (a.localized_date > b.localized_date ? 1 : -1)) || [];
        const monthlyGroupedData = {};

        sortedData.forEach(d => {
          const [year, month] = d.localized_date.split('T')[0].split('-');
          const monthYear = `${month}/${year.slice(2)}`;
          //Removing Z from localized_date to prevent luxon timezone conversion
          d.localized_date = d.localized_date.replace("Z", "");
          //Group daily activity by month/year
          if (monthlyGroupedData[monthYear]) {
            monthlyGroupedData[monthYear].push(d);
          } else {
            monthlyGroupedData[monthYear] = [d];
          }
        });
        setVclSummaryData(monthlyGroupedData);
      })
      .catch(error => console.error("Error fetching vehicle daily activity: ", error));
  };

  const getScoredOnExplanation = (rec, scored_on, is_ld) => {
    const vehicleCategory = is_ld ? 'Light Duty' : 'Medium and Heavy Duty';

    switch (rec) {
      case "No Change":
        return `This vehicle was compared to all available ${vehicleCategory} EVs, none of which were a good economic and operational fit. These scores are based on the EV that was the best fit: ${scored_on}.`;
      case "Possible Sedan Fit":
        return `This vehicle was compared to all available EVs in its class, none of which were a good economic and operational fit. It was then compared against electric sedans and was identified to be a good fit for an electric sedan. These scores are based on the electric sedan model that was the best fit: ${scored_on}.`;
      case "Operational Fit":
        return `This vehicle was compared to all available ${vehicleCategory} EVs, none of which were a good economic fit. However, at least one vehicle was a good operational fit. These scores are based on the EV that was the best operational fit: ${scored_on}.`;
      case "No Models Fit":
        return `There are no EV candidates within this vehicle class. These scores are based on the EV that was the best fit: ${scored_on}.`;
      default:
        return '';
    }
  };

  let explanation, candResults, component, newIce;

  if (!vclResults || !candidateResults || !parkingData) {
    component = (
      <>
        <HeaderSingleVcl vcl={{ asset_id: '' }} />
        <div className="ezev-loading-wrapper">
          <Loading />
        </div>
      </>
    );
  } else {
    explanation = getScoredOnExplanation(vclResults.recommendation, vclResults.scored_on, vclResults.is_ld);

    candResults = [
      ...candidateResults.phevs.map(item => ({ ...item, is_phev: true, is_bev: false })),
      ...candidateResults.bevs.map(item => ({ ...item, is_phev: false, is_bev: true }))
    ];

    // Map effective battery kWh to candidates
    newIce = candidateResults.ice_comp;
    candResults.forEach(c => {
      const cand = props.candidates.find(e => e.id === c.id) || props.candidates.find(e => e.ymm === c.ymm);
      if (!cand) {
        c.effective_battery_kwh = '-';
        return;
      }
      c.price = cand.net_price;
      c.usable_kwh = cand.usable_kwh !== undefined ? Math.round(cand.usable_kwh * 10) / 10 : '-';
      c.effective_battery_kwh = cand.usable_kwh !== undefined
        ? calcEffectiveBattery(props.settings.charge_to_ld, props.settings.discharge_to_ld, props.settings.charge_to_mdhd, props.settings.discharge_to_mdhd, vclResults.is_ld, cand.usable_kwh)
        : '-';
    });

    component = (
      <SingleVclPage
        vcl={vclResults}
        scoredOnText={explanation}
        localKwh={props.localKwh}
        parking={parkingData}
        cands={candResults}
        newIce={newIce}
        settings={props.settings}
        userSettings={props.user.userSettings}
        dbDisplayName={props.dbDisplayName}
        apiURL={props.apiURL}
        user={props.user}
        graphData={vclSummaryData}
        products={props.products}
      />
    );
  }

  return (
    <div>
      <Suspense fallback={<HeaderSingleVcl vcl={{ asset_id: '' }} />}>
        {component}
      </Suspense>
    </div>
  );
};

export default SingleVclController;