import React, {useState, useMemo, useCallback, useRef } from "react";
import CreateAccountModal from "./create-acct-modal";
import Loading from "../swt-loading";
import AdminTableView from "./AdminTableView";
import IntegrationsDropdown from './IntegrationsDropdown';
import Search from "./search";
import * as S from '../../../../styles/core-styles/AdminTools-styles';
import { formatDateTimeUTC } from "../UtilityFunctions";
import { _inputSort, _stringSort, _dropdownSort } from "./TableHelpers";

const SERVER_ERRORED_MESSAGE = "There was an error with your request. Please try again"

export default function SuperAdminDatabaseSettings(props){

  const integrationsObj = require("../integrations");
  const integrations = integrationsObj.integrations;
  const [showModal, setShowModal] = useState( false);
  const [databases, setDatabases] = useState(null);
  const [updateDatabases, setUpdateDatabases] = useState(true);
  const [products, setProducts] = useState(null)
  const [matchingDatabases, setMatchingDatabases] = useState(null);
  
  const skipPageResetRef = useRef(false);

  const updateDatabaseDisplayName = ((dbName, displayName)=>{
    try{
      const url = `${props.apiURL}updateDatabaseDisplayName`;
      const body = {dbName: dbName, displayName: displayName}
      fetch(url, {
        headers: { 
          Authorization: `Bearer ${props.user.token}`,
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify(body)
      })
        .then((resp) =>{
          return resp.json();
        })
        .then((data) => {
          if (data.status === "error")
            alert(SERVER_ERRORED_MESSAGE);
          else
            alert("Databases updated successfully.")
        });
    } catch (err) {
      console.error(err);
    }
  });

  useMemo(() => {
    if(!updateDatabases)return;
    //if (!databases || !products)resetState();
    try {
      const url = `${props.apiURL}getDatabaseList?email=${props.user.email}`;
      fetch(url, {
        credentials: "same-origin",
        headers: { Authorization: `Bearer ${props.user.token}` },
      })
      .then(resp => resp.json())
      .then(data => {
        if (data.status === "error")
          alert(SERVER_ERRORED_MESSAGE);
        else {
          setDatabases(data.data);
          setUpdateDatabases(false);
        }
      }
      )
    } catch (err) {
      console.error(err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[updateDatabases]);

  useMemo(() => {
    let url = props.apiURL + "getProductList";
    fetch(url, {
      headers: { Authorization: `Bearer ${props.user.token}` },
    })
      .then(props.handleFetchErrors)
      .then((res) => res.json())
      .then((data) => {
        if (data.data) {
          const products = data.data.filter((p) => p.deprecated !== true);
          setProducts(products);
        } else throw Error("No product list available");
      })
      .then(() => {
        return true;
      })
      .catch((err) => console.error("error on product list call", err));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  const boolSort = useMemo(() => (rowA, rowB, columnId) => {
    const a = rowA.values[columnId].props.bool;
    const b = rowB.values[columnId].props.bool;
    return a > b ? 1 : -1;
  },[]);

  const dateSort = useMemo(() => (rowA, rowB) => {
    const a = rowA.original.last_login_original;
    const b = rowB.original.last_login_original;
    if(a && !b) {
      return 1;
    } else if(!a && b) {
      return -1;
    } else {
    return a > b ? 1 : -1;
    }
  },[])

  const tableColumns = [
    {Header: "Database", accessor: "database", sortType: _stringSort},
    {Header: "Display Name", accessor: "display_name", sortType: _inputSort},
    {Header: 'Total Users', accessor: 'all_user_count', width: 120},
    {Header: 'Non Super Admin Users', accessor: 'non_super_admin_user_count', width: 120},
    {Header: 'User Role Count\n(S/P/F/B)', accessor: 'user_role_counts', width: 200, explainText: 'This is the number of users assigned to each user role in the database. Each user is counted once based on their highest role.\nS = Super Admin\nP = Partner Admin\nF = Fleet Admin\nB = Basic User'},
    {Header: 'Last Login', accessor: 'last_login', sortType: dateSort, explainText: 'Last login is user specific and does not include super admin user logins. This is the most recent login for any user that is a member of the database.'},
    {Header: "Integration", accessor: "integration", sortType: _dropdownSort},
    {Header: "ezEV (TD)", accessor: "ezev-td", sortType: boolSort, width: 120},
    {Header: "ezEV (FX)", accessor: "ezev-fx", sortType: boolSort, width: 120},
    {Header: "ionEV", accessor: "ionev", sortType: boolSort, width: 120},
    {Header: "Emit", accessor: "emit", sortType: boolSort, width: 120},
    {Header: "ezIO", accessor: "evse_map", sortType: boolSort, width: 120},
    {Header: "Speedn", accessor: "speedn", sortType: boolSort, width: 120},
    {Header: "Idlin", accessor: "idling", sortType: boolSort, width: 120},
    {Header: "Shifted", accessor: "shifted", sortType: boolSort, width: 120},
    {Header: "Fueln", accessor: "fueln", sortType: boolSort, width: 120},
    {Header: "Parking Map", accessor: "parking-map", sortType: boolSort, width: 120},
    {Header: "Admin", accessor: "admin", sortType: boolSort, width: 120},
    {Header: "Dev Tools", accessor: "dev_tools", sortType: boolSort, width: 120},
  ]

  function addPurchase(purchase, userDatabases){
    const dbName = purchase.account;
    const productName = tableColumns.find(p => p.accessor === purchase.purchases)?.Header ?? purchase.purchases; // find the prettified name of the product in the table columns array if it exists, otherwise use the internal name
    if (purchase === null || purchase === undefined)
      console.error("no data for request");
    let url = `${props.apiURL}addProduct/`;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.user.token}`,
      },
      body: JSON.stringify(purchase),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "error") {
          window.alert(`A server error occurred during your request.`);
        } else {
          setDatabases(userDatabases);
          window.alert(`Added product ${productName} to database ${dbName}.${(props.activeDb === dbName) ? " You may need to refresh the page for changes to take effect." : ""}`);
        }
      });
  };

  function deletePurchase(data, userDatabases){
    const dbName = data.account;
    const productName = tableColumns.find(p => p.accessor === data.purchases)?.Header ?? data.purchases; // find the prettified name of the product in the table columns array if it exists, otherwise use the internal name
    if (data === null || data === undefined)
      console.error("no data for request");
    let url = `${props.apiURL}deleteProduct/`;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.user.token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "error") {
          window.alert(`There was a server error during your request.`);
        } else {
          setDatabases(userDatabases);
          window.alert(`Removed product ${productName} from database ${dbName}.${(props.activeDb === dbName) ? " You may need to refresh your dashboard window for changes to take effect." : ""}`);
        }
      });
  };

  function createDatabase(data){
    const url = `${props.apiURL}setupDb`;
    data.dbName = data.dbName.toLowerCase();
    data.display_name = data.companyName;
    try {
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          addUserToDb(data.dbName);
        });
    } catch (err) {
      console.error("error", err);
    }
  };

  function addUserToDb(dbName){
    const url = `${props.apiURL}updateUserDatabases`;
    // NOTE want account to be specified in setup modal?
    var data = {
      email: props.user.email,
      database: dbName,
      account: dbName,
    };
    try {
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
        body: JSON.stringify(data),
      })
      .then((resp) => {return resp.json()})
      .then((data) => {
        if (data.status === "error")
          alert("There was a server error during your request.");
        else 
          alert('Updated users databases.')
      });
    } catch (err) {
      console.error("error", err);
    }
  };

  function updateIntegration(db, value) {
    var data = { db: db, integration: value.toLowerCase() };
    if (db === undefined || value === undefined)
      console.error("no data for request");
    let url = `${props.apiURL}updateIntegration/`;
    try {
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.user.token}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "error")
            alert(SERVER_ERRORED_MESSAGE);
          else 
            alert(`Updated integration for database ${db}.`)
        })
        // .then((data) => {
        //   if (data.status === "success") getInitialData();
        //   else {
        //     window.alert(`An error occured. Please try again`);
        //   }
        // });
    } catch (err) {
      console.error("error", err);
    }
  };

 function handleProductClick(database, product) {
    let userDatabases = [...databases];
    skipPageResetRef.current=false;
    userDatabases.forEach((acct) => {
      if (acct.database === database) {
        if (acct.products.includes(product)) {
          let productToRemove = { account: database, purchases: product };
          let filtered = acct.products.filter((prod) => prod !== product);
          acct.products = filtered;
          deletePurchase(productToRemove, userDatabases);
          let updatedProducts = acct.products.filter((p) => p !== product);
          acct.products = updatedProducts;
        } else if (!acct.products.includes(product)) {
          let productToAdd = { account: database, purchases: product };
          acct.products.push(product);
          addPurchase(productToAdd, userDatabases);
        } else console.error("Product change failed");
      }
    });
  };

  function createAcct (data){
    createDatabase(data);
    setShowModal(false)
  };

  function createDatabaseOptions () {
    if (integrations === undefined || integrations === null) return;
    return integrations.map((opt) => {
      if (opt.available)
        return (
          <option key={opt.id} id={opt.id} value={opt.id}>
            {opt.name}
          </option>
        );
      return null;
    });
  };

  function handleIntegrationChange(e, db) {
    skipPageResetRef.current=false;
    updateIntegration(db, e);
    setUpdateDatabases(true);
  };

  function _getTableIndex(db) {
    const idxArr = databases.map((d) => {
      return d.database;
    });
    return idxArr.indexOf(db);
  }

  function handleInputChange(e){
    const ALPHANUMERIC_REGEX = new RegExp("^[\\w\\-\\(\\) ]*$") //Prevents all special characters except for " - _ ( ) "
    if(ALPHANUMERIC_REGEX.test(e.target.value) === false) {
      e.preventDefault();
      return;
    }
    skipPageResetRef.current=false;
    const dbs = [...databases];
    const dbObj = dbs[_getTableIndex(e.target.getAttribute("id"))];
    dbObj["mutated"] = true;
    let val = e.target.value;
    val = val.replace(/['"]/g, "");
    dbObj[e.target.getAttribute("accessor")] = val;
    setDatabases(dbs);
  }

  const InputTableCell = useCallback((props) => 
      <input className="swt-admin-input"
             style={props.styles}
             id={props.id}
             title={props.label}
             key={props.id}
             value={`${props.label}`}
             accessor={props.accessor}
             onChange={props.handleOnChange}
             type={props.type}
             onMouseOver={props.onMouseOver ? ()=>props.onMouseOver(props.vin) : ()=>{}}
             onMouseOut={props.onMouseOver ? ()=>props.onMouseOut(props.vin) : ()=>{}}
      />,[]);

  const BoolTableCell = useCallback((props) =>
        <input
            className="swt-admin-table-input"
            type="checkbox"
            id={props.id}
            key={props.id}
            accessor={props.accessor} 
            style={{color: props.bool ? "black" : "transparent"}} 
            onChange={props.handleOnClick}
            checked={(props.bool === null || typeof props.bool === "undefined") ? false : props.bool}
            disabled={typeof props.bool === "undefined" ? true : false}
        />,[]);


  function sanitizeIntegrationId(integration, options) {
    let availableIntegrations = options.filter((opt) => opt.available === true)
    if (availableIntegrations.find((i) => i.id === integration)) {
      return integration;
    } else {
      return 'none'
    }
  }

  const mappedDatabases = useMemo(() => {
    if(!databases || !matchingDatabases) return null;
    if(databases.length < 1)return [];
    const immutableDatabases = JSON.parse(JSON.stringify(databases));
    const matchingDbs = matchingDatabases.map((d) => {return d.database});
    return immutableDatabases.filter((acc) => {
        if(matchingDbs.indexOf(acc.database) < 0)return null;
        acc.user_role_counts = `${acc.super_admin_user_count}/${acc.partner_admin_user_count}/${acc.fleet_admin_user_count}/${acc.basic_user_count}`;
        acc.last_login_original = JSON.parse(JSON.stringify(acc.last_login))
        acc.last_login = formatDateTimeUTC(acc.last_login);
        acc.display_name = <InputTableCell id={acc.database} label={acc.display_name} accessor={"display_name"} handleOnChange={handleInputChange}type={"text"}/>
        acc['ezev-td'] = <BoolTableCell id={acc.database} accessor={"ezev-td"} bool={acc.products.includes("ezev-td")} handleOnClick={() => {handleProductClick(acc.database, 'ezev-td')}} />
        acc['ezev-fx'] = <BoolTableCell id={acc.database} accessor={"ezev-fx"} bool={acc.products.includes("ezev-fx")} handleOnClick={() => {handleProductClick(acc.database, 'ezev-fx')}} />
        acc.ionev = <BoolTableCell id={acc.database} accessor={"ionev"} bool={acc.products.includes("ionev")} handleOnClick={() => {handleProductClick(acc.database, 'ionev')}} />
        acc.emit = <BoolTableCell id={acc.database} accessor={"emit"} bool={acc.products.includes("emit")} handleOnClick={() => {handleProductClick(acc.database, 'emit')}} />
        acc.speedn = <BoolTableCell id={acc.database} accessor={"speedn"} bool={acc.products.includes("speedn")} handleOnClick={() => {handleProductClick(acc.database, 'speedn')}} />
        acc.idling = <BoolTableCell id={acc.database} accessor={"idling"} bool={acc.products.includes("idling")} handleOnClick={() => {handleProductClick(acc.database, 'idling')}} />
        acc.shifted = <BoolTableCell id={acc.database} accessor={"shifted"} bool={acc.products.includes("shifted")} handleOnClick={() => {handleProductClick(acc.database, 'shifted')}} />
        acc.evse_map = <BoolTableCell id={acc.database} accessor={"evse_map"} bool={acc.products.includes("evse-map")} handleOnClick={() => {handleProductClick(acc.database, 'evse-map')}} />
        acc.fueln = <BoolTableCell id={acc.database} accessor={"fueln"} bool={acc.products.includes("fueln")} handleOnClick={() => {handleProductClick(acc.database, 'fueln')}} />
        acc['parking-map'] = <BoolTableCell id={acc.database} accessor={"parking-map"} bool={acc.products.includes("parking-map")} handleOnClick={() => {handleProductClick(acc.database, 'parking-map')}} />
        acc.admin = <BoolTableCell id={acc.database} accessor={"admin"} bool={acc.products.includes("admin")} handleOnClick={() => {handleProductClick(acc.database, 'admin')}} />
        acc.dev_tools = <BoolTableCell id={acc.database} accessor={"dev_tools"} bool={acc.products.includes("dev-tools")} handleOnClick={() => {handleProductClick(acc.database, 'dev-tools')}} />
        
        acc.integration = <IntegrationsDropdown
          defaultValue={sanitizeIntegrationId(acc.integration, integrations)}
          integrations={integrations}
          handleChange={handleIntegrationChange}
          id={acc.database}
        />
        return acc;
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[databases, matchingDatabases])

  const handleSave = () => {
    databases.forEach((db)=>{
      if(db.mutated)updateDatabaseDisplayName(db.database, db.display_name);
    });
  };

    if (!databases || !products)
      return <Loading />;
    return (
      <>
          <S.CandidatesTableHeaderContainer>
              <div>
                  <S.AdminTableExplainTextPrimary>List of all databases.</S.AdminTableExplainTextPrimary>
                  <S.AdminTableExplainTextSub>Click a column name to sort the table.</S.AdminTableExplainTextSub> 
                  <S.SearchContainer>
                    <Search allValues={databases} setMatchingValues={setMatchingDatabases} skipPageResetRef={skipPageResetRef} disabled={databases ? false : true}/>
                  </S.SearchContainer>
                  <S.CtaButtonWrapper>
                    <S.CtaButton onClick={() => setShowModal(!showModal)}>Add</S.CtaButton>
                    <S.CtaButton onClick={handleSave}>Update</S.CtaButton>
                  </S.CtaButtonWrapper>
              </div>
          </S.CandidatesTableHeaderContainer>
          {!mappedDatabases && <Loading />}
          {(mappedDatabases) && 
            <AdminTableView 
              columns={tableColumns}
              data={mappedDatabases}
              stickyCols={2}
              skipPageResetRef={skipPageResetRef}
            />
          }

          <CreateAccountModal
            show={showModal}
            user={props.user}
            apiURL={props.apiURL}
            handleClose={() => setShowModal(!showModal)}
            integrations={integrations}
            credentials={props.credentials}
            createAcct={createAcct}
            createDatabaseOptions={createDatabaseOptions}
            handleIntegrationChange={handleIntegrationChange}
            passwordRules={props.passwordRules}
          />
      </>
    );
  }
