import { useState,useEffect,useRef } from "react"
import { useSortBy, useTable, useFilters, useFlexLayout, usePagination, HeaderGroup } from "react-table"
import { Column } from "react-table/index"
import styled from "styled-components/macro"
import arrowUp from "../../images/ic-arrow-asc-4px-7px.gif"
import arrowDown from "../../images/ic-arrow-desc-4px-7px.gif"
import arrowUnsel from "../../images/ic-arrow-unsort-4px-9px.gif"
import * as S from "../../styles/ezio-styles/Table-styles"
import * as ColorScheme from "../../styles/ezio-styles/ColorScheme";

export type TableProps = {
    columns: Array<Column>,
    data: Array<any>
    hoverHighlight: boolean
    tableWidth: string
}

type StylesProps = {
    hoverHighlight: boolean
    tableWidth: string
}

type SawatchHeader = HeaderGroup & {showToolTip: (Boolean, Object) => {}, showInInterface: boolean, showInDownload: boolean}

const Styles = styled.div<StylesProps>`

max-height: 440px;
font-family: "Arimo", "Roboto";
font-size: 16px;
border-spacing: 0;
overflow: auto;
${({ tableWidth }: StylesProps) => (
        `width: ${tableWidth};`
    )}

table{
    border-collapse: collapse;
    max-height: 440px;
    width: 100%;
}



 thead {
    position: sticky;
    top: 0;
}

thead > tr{
    background-color: ${ColorScheme.ezio_grey_green};
}
thead th{
    background-color: inherit;
    color: ${ColorScheme.ezio_darkest_grey};
    height: 90px;
    font-size: 15px;
    text-align: start;
    vertical-align: middle;
    padding: 1em;
}

td, td > *{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}


tr {
    height: max-content;
    min-height: 4rem;
    display: flex;
    align-items: center;
}
td{
    padding-left: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    overflow: auto;
}


${({ hoverHighlight }: StylesProps) => (
        hoverHighlight ? `tbody tr:hover{
            color: ${ColorScheme.ezio_dark_green};
            text-decoration: underline;
            text-shadow:0px 0px 0.1px ${ColorScheme.ezio_dark_green};
            text-decoration-color: ${ColorScheme.ezio_dark_green};
        }` : ""
    )
    }

thead tr th:hover{
    text-decoration: underline;
}

.swt-ezio-even-row{
  background-color: ${ColorScheme.ezio_light_grey};
}

.swt-ezio-odd-row{
  background-color: white;
}


.swt-ezio-search-input{
    width: 8rem;
}

.swt-sort-arrow{
    background-repeat: no-repeat;
    background-size: 7px;
    background-position: right 0 center;
}
.both{
    background-image: url(${arrowUnsel});
}

.asc{
    background-image: url(${arrowUp})
}
.desc{
    background-image: url(${arrowDown});
}

`

// Create a default prop getter
const defaultPropGetter = () => ({})

export default function Table({ columns, data, defaultPrimarySort, defaultSecondarySort, defaultTertiarySort, getRowProps = defaultPropGetter, hoverHighlight = true, tableWidth }: any) {
    let tableSort = [];
    if (defaultPrimarySort) tableSort.push({ id: defaultPrimarySort.columnId, desc: defaultPrimarySort.sortDesc});
    if (defaultSecondarySort) tableSort.push({ id: defaultSecondarySort.columnId, desc: defaultSecondarySort.sortDesc});
    if (defaultTertiarySort) tableSort.push({ id: defaultTertiarySort.columnId, desc: defaultTertiarySort.sortDesc});

    // Use the state and functions returned from useTable to build your UI
    const {

        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,

        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,

        state: { pageIndex },
    } = useTable({
        columns,
        data,
        initialState: {
            sortBy: tableSort,
            pageIndex: 0,
            pageSize: 20
        }
    },
        useFlexLayout,
        useFilters,
        useSortBy,
        usePagination
    )
    const [pageInput, setPageInput] = useState<string | number>(pageIndex + 1);
    const tableRef = useRef(null);
  
      useEffect(() => {
        tableRef.current.scrollTo({top:0});
      }, [pageIndex]);
    
    // Render the UI for your table
    return (
        <S.TableContainer>
            <Styles hoverHighlight={hoverHighlight} tableWidth={tableWidth} ref={tableRef}>
                <table {...getTableProps()}>
                    {data.length === 0 && <S.TableEmptyCaption><S.TableEmptyText>No Data to Display. Try adjusting filters for more results.</S.TableEmptyText></S.TableEmptyCaption>}
                    <thead>

                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => {
                                    const c = column as SawatchHeader;
                                    if (c.showInInterface) { // only show headers for columns that should be shown in the interface
                                        return (
                                            <th {...c.getHeaderProps()}>
                                            <div {...c.getSortByToggleProps({ title: undefined })} className={`swt-sort-arrow  ${c.isSorted
                                                ? c.isSortedDesc
                                                    ? "desc"
                                                    : "asc"
                                                : "both"}`}>
                                                <div 
                                                    onMouseEnter={(e)=>{
                                                        if(c['showToolTip']){
                                                            c.showToolTip(true, e);
                                                        }}
                                                    }
                                                    onMouseLeave={(e)=>{
                                                        if(c['showToolTip']){
                                                            c.showToolTip(false, e); 
                                                        }}
                                                    }
                                                >
                                                {c.render('Header')}
                                                </div>
    
                                            </div>
                                            <S.SearchBar>{c.canFilter ? c.render('Filter') : null}</S.SearchBar>
                                        </th>
                                        )
                                    } else {
                                        return null;
                                    }
                                })}
                            </tr>
                        ))}
                    </thead>


                    <tbody {...getTableBodyProps()}>
                        {page.map((row, idx) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps(getRowProps(row))} className={idx % 2 === 0 ? "swt-ezio-even-row" : "swt-ezio-odd-row"}>
                                    {row.cells.map(cell => {
                                        const c = cell.column as SawatchHeader;
                                        if(c.showInInterface) { // only show cells for columns that should be shown in the interface
                                            return <td id={`row-${idx}-${c.id}-testid`} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        }
                                        else {
                                            return null;
                                        }
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>

                </table>

            </Styles>
            <S.Pagination>

                <button onClick={() => {
                    setPageInput(pageIndex)
                    previousPage()
                }} disabled={!canPreviousPage}>
                    {'Previous'}
                </button>
                <span>
                    Page{' '}
                    <input
                        type="number"
                        value={pageInput}
                        onChange={e => {

                            //Allow blank inputs
                            if (e.target.value === "") {
                                setPageInput("")
                            }
                            //Allow number inputs within acceptable range
                            else if (!isNaN((e.target.value as any))) {
                                setPageInput(Math.min(Math.max(parseInt(e.target.value), 1), pageCount))
                            }
                            //Disallow other inputs
                            else {
                                setPageInput(pageIndex + 1)
                            }

                        }}
                        onBlur={e => {
                            const destination = e.target.value
                            //No non-number inputs
                            isNaN(Number(destination))
                                ? setPageInput(pageIndex + 1)
                                //No negative inputs or inputs higher than the page count
                                : Number(destination) < 1 || Number(destination) > pageCount
                                    ? setPageInput(pageIndex + 1)
                                    //Otherwise, accept the input
                                    : gotoPage(Number(destination) - 1)

                        }}
                        style={{ width: '100px' }}

                    />
                    {' '}of {pageCount}
                </span>
                <button onClick={() => {
                    setPageInput(pageIndex + 2)
                    nextPage()
                }} disabled={!canNextPage}>
                    {'Next'}
                </button>
            </S.Pagination>
        </S.TableContainer>
    )
}