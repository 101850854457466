import React from 'react';
import { useRef, useEffect } from 'react';
import { useSortBy, useTable, useFilters, useFlexLayout } from "react-table"
import * as S from "../../styles/ezev-fx-styles/VehiclesTable-styles"
import { usePagination } from 'react-table';

const defaultPropGetter = (column) => { }
const VehiclesTable = ({ data, columns, getColumnProps = defaultPropGetter }) => {
  
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    rows,

    state: { pageIndex },
  } = useTable({
    columns,
    data,
    initialState: {
      sortBy: [
        {
          id: "overall",
          desc: true
        },
        {
          id: "asset_id",
          desc: false
        }

      ],
      pageIndex: 0,
      pageSize: 30
    }
  },
    useFlexLayout,
    useFilters,
    useSortBy,
    usePagination
  );

  const tableRef = useRef(null);

  useEffect(() => {
    tableRef.current.scrollTo({top:0});
  }, [pageIndex]);

  return (
    <>
      <S.StyledTable {...getTableProps()} ref={tableRef}>
        <table data-testid="vehiclesTable-testid">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column =>
                (
                  <th {...column.getHeaderProps()}>
                    <div {...column.getSortByToggleProps()} className={`swt-sort-arrow  ${!column.canSort
                      ? ""
                      : column.isSorted
                        ? column.isSortedDesc
                          ? "ezev-desc"
                          : "ezev-asc"
                        : "ezev-both"}`}>
                      {column.render('Header')}

                    </div>
                    <S.SearchBar>{column.canFilter ? column.render('Filter') : null}</S.SearchBar>
                  </th>
                )
                )}
              </tr>
            ))}
          </thead>
          <tbody  {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} className={i % 2 === 0 ? "swt-ezio-even-row" : "swt-ezio-odd-row"}>
                  {row.cells.map(cell => {
                    return <td data-testid={`row-${i}-${cell.column.testid}-testid`} className={cell.column.className ?? ""} {...cell.getCellProps(getColumnProps(cell.column))}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>

      </S.StyledTable>
      {rows.length < 1 ?
      <S.NoDataMsg data-testid="noDataMessage-testid">There are no vehicles to display</S.NoDataMsg>
      :
      <S.PaginationContainer>
        <S.PaginationButtons onClick={previousPage} disabled={!canPreviousPage}>Previous</S.PaginationButtons>
        <S.PaginationDetails>Page {pageIndex + 1} of {pageOptions.length}</S.PaginationDetails>
        <S.PaginationButtons onClick={nextPage} disabled={!canNextPage} data-testid="vehiclesTableNextPageButton-testid">Next</S.PaginationButtons>
      </S.PaginationContainer>
      }
    </>
  )

}
export default VehiclesTable;
