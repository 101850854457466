import React, { useState, useMemo, useEffect, useRef, useReducer, useCallback, Suspense } from 'react'
import { ChargeLocation } from "../swt-ezio";
import * as S from "../../styles/ezio-styles/LocationDetail-styles"
//import StatBox from "./StatBox"
import Table from "./Table";
import { columnsToExcelHeaders, rowsToData } from "../../utils/ezio-utils/ExcelUtils"
import { locationPageTableColumns } from "./TableHelpers";
import GraphDaily from './GraphDaily';
import { ReactComponent as DownArrow } from "../../images/dropdowndownarrow-17px-18px.svg"
import ChargeTypeDropdown from "./ChargeTypeDropdown";
import { OptionType } from "./ChargeTypeDropdown";
import DateSelectors from "./DateSelectors";
import useOutsideAlerter from '../../utils/ezio-utils/useOutsideAlerter'
import GraphMonthly from './GraphMonthly';
import { useHistory, useParams } from "react-router-dom";
import { StatView } from "@sawatchlabs/swt-components";
import { vehicleIdDisplay, formatChargingStatus } from './TableHelpers';
import { DateTime } from 'luxon';
import { filterByCategory, filterByGroups, filterByVehicleClass } from '../../utils/ezio-utils/DataUtils';

//code-splitting imports
const ExcelDownloadButton = React.lazy(() => import("./ExcelDownloadButton"));

const MINUTES_PER_BLOCK = 15;

export type LocationDetailProps = {
    chargeLocations: Map<string, ChargeLocation>
    selectedChargeLocation?: string
    totalVehicleCount: number
    viewMode: string
    req: any
    category: string
    groups: Array<any>
    electrification: number
    vehicleClasses: Array<string>
    dbDisplayName: string,
    userSettings: any,
    selectedVins: Array<string>,
    forceNavigate: Function,
    ALL_LOCATIONS_ID: string
}

enum ViewMode {
    Monthly,
    TimeOfDay
}

type StatBoxesType = {
    vehiclesProjectedToCharge: {
        value: string
        subValue: string
        caption?: string
    }
    vehiclesWithPassingEvScore: {
        value: string
        caption?: string
    },
    highestPeakDemand: {
        value: string
        subValue: string
        caption?: string
    }
    level2ChargingPortsNeeded: {
        value: string
        caption?: string
        subCaption?: string
    },
    dcfcChargingPortsNeeded: {
        value: string
        caption?: string
        subCaption?: string
    },
    totalLocationCharging: {
        value: string,
        subValue: string,
        caption?: string
    }
}

type DateBounds = {
    min: DateTime
    max: DateTime
}

type Params = {
    urlId: string
}



export default function LocationDetail({ viewMode: initialViewMode, selectedChargeLocation, chargeLocations, req, totalVehicleCount, category, groups, vehicleClasses, electrification, dbDisplayName, userSettings, selectedVins, forceNavigate, ALL_LOCATIONS_ID }: LocationDetailProps) {
    const [chargeLocation, setChargeLocation] = useState<ChargeLocation>();
    const [selectedDates, setSelectedDates] = useState<DateBounds>({min: DateTime.utc(), max: DateTime.utc()});
    const [dateBounds, setDateBounds] = useState<DateBounds>(); //objects so we can work with proper date objects
    const [selectedChargeType, setSelectedChargeType] = useState<OptionType>({ value: 'unmanaged', label: 'Unmanaged Charging' });

    const [showVehicleCountToolTip, setShowVehicleCountToolTip] = useState<Boolean>(false);
    const [showPrimaryParkingToolTip, setShowPrimaryParkingToolTip] = useState<Boolean>(false);
    const [showParkingPercentageToolTip, setShowParkingPercentageToolTip] = useState<Boolean>(false);
    const [showPortCountToolTip, setShowPortCountToolTip] = useState<Boolean>(false);
    const [showL2PortCountToolTip, setShowL2PortCountToolTip] = useState<Boolean>(false);

    const [cursorCoords, setCursorCoords] = useState({ x: 0, y: 0 });
    const [navOpen, setNavOpen] = useState(false);

    //date sensitive state for stat views:
    const [inBoundsChargingVehicles, setInBoundsChargeVehicles] = useState<number>(0);
    const [inBoundsVclsWithRec, setInBoundsVclsWithRec] = useState<number>(0);
    const [notAtLocationChargingKwh, setNotAtLocationChargingKwh] = useState<number>(0);
    const [atLocationChargingKwh, setAtLocationChargingKwh] = useState<number>(0);

    const navRef = useRef(null);
    useOutsideAlerter(navRef, () => setNavOpen(false));
    const { urlId } = useParams<Params>();

    // Check if the location detail page has been navigated to with the all locations ID (ie. via a refresh or browser history navigation). If so, force a controls reset with the location UUID provided by the URL. forceNavigate will handle if the URL is invalid (though that could only happen with a manual URL entry, which can currently only happen when running locally).
    useEffect(() => {
        if(selectedChargeLocation === ALL_LOCATIONS_ID) {
            console.error("Location summary page was loaded with 'All Locations' selected. Attempting to reload with the location ID from the URL.")
            forceNavigate(urlId);
        }
    // This check should only run on initial component render, since it's impossible for users to select 'All Locations' once inside this page, so leaving the dependency array empty intentionally.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const handleShowPrimaryParkingToolTip = (show: Boolean, mouseEvent: any) => {
        setCursorCoords({x:mouseEvent.pageX, y:mouseEvent.pageY});
        setShowPrimaryParkingToolTip(show);
    }
    const handleShowVehicleCountToolTip = (show: Boolean, mouseEvent: any) => {
        setCursorCoords({x:mouseEvent.pageX, y:mouseEvent.pageY});
        setShowVehicleCountToolTip(show);
    }
    const handleShowPortCountToolTip = (show: Boolean, mouseEvent: any, type: string) => {
        setCursorCoords({x:mouseEvent.pageX, y:mouseEvent.pageY});
        if(type === 'l2') {
            setShowL2PortCountToolTip(show)
        } else {
            setShowPortCountToolTip(show);   
        }
    }
    const handleShowParkingPercentageToolTip = (show: Boolean, mouseEvent: any) => {
        setCursorCoords({x:mouseEvent.pageX, y:mouseEvent.pageY});
        setShowParkingPercentageToolTip(show);
    }
    
    const excelDataSort = (a: any, b: any) => {
        // [4] = avg charge hours
        // [9] = homebase
        
        // Sort by Charging/Not Charging first
        if(formatChargingStatus(a[4]) < formatChargingStatus(b[4])) return 1;
        if(formatChargingStatus(a[4]) > formatChargingStatus(b[4])) return -1;

        // Secondary sort by homebase value
        if(a[9] < b[9]) return -1;
        if(a[9] > b[9]) return 1;

        // Tertiary charge values sort
        return a[4] < b[4] ? 1 : -1;
    }

    const obj = locationPageTableColumns.find((vtc) => vtc.Header === "Primary Parking Location");
    obj.showToolTip = handleShowPrimaryParkingToolTip;

    const [viewMode, setViewMode] = useState<ViewMode>(initialViewMode === "Monthly" ? ViewMode.Monthly : ViewMode.TimeOfDay)
    useEffect(() => {
        setViewMode(initialViewMode === "Monthly" ? ViewMode.Monthly : ViewMode.TimeOfDay)
    }, [initialViewMode])

    //Go through the master location list to find the address
    useMemo(() => {
        if (!selectedChargeLocation)return;
        
        const matchingLocation: ChargeLocation | undefined = chargeLocations.get(selectedChargeLocation);
        if (matchingLocation) {
            setChargeLocation(matchingLocation);
        }
    }, [selectedChargeLocation, chargeLocations]);

    const history = useHistory();

    useMemo(() => {
        //calc dates from history.location.state or req if no state
        let start: DateTime, end: DateTime = undefined;
        if(history.location.state){
            const s:any = history.location.state;
            // first check for date bounds being passed from a click on the monthly graph
            if(s.dateBounds){
                // start at the maximum of (first day of month, minimum of app-level date bound)
                start = DateTime.fromMillis(Math.max((req.beginDate.ts), (s.dateBounds.min.ts))).setZone('UTC-0');
                // end at the minimum of (last day of month, maximum of app-level date bound)
                end = DateTime.fromMillis(Math.min((req.endDate.ts), (s.dateBounds.max.ts))).setZone('UTC-0');

            // then check for date bounds being passed from swapping to monthly view from daily view, if we're on the daily view 
            // this prevents the interior date selectors from defaulting back to the entire range, which causes graph lag and defeats the purpose of the daily demand view
            } else if (s.lastDailyDemandDateBounds && history.location.pathname.includes("kw-demand-daily")) {
                // start at the maximum of (first day of month, minimum of app-level date bound)
                start = DateTime.fromMillis(Math.max((req.beginDate.ts), (s.lastDailyDemandDateBounds.beginDate.ts))).setZone('UTC-0');
                // end at the minimum of (last day of month, maximum of app-level date bound)
                end = DateTime.fromMillis(Math.min((req.endDate.ts), (s.lastDailyDemandDateBounds.endDate.ts))).setZone('UTC-0');
            // if we are swapping to the daily demand page and don't have date bounds (ie. navigated directly to monthly demand)
            // get the first month of the period or the entire range, whichever is smaller
            } else if (!s.lastDailyDemandDateBounds && history.location.pathname.includes("kw-demand-daily")) {
                const thirtyDaysAfterStart = req.beginDate.plus({ days: 30 });
                start = req.beginDate;
                end = DateTime.min(req.endDate,thirtyDaysAfterStart);
            }
        }
        if((!start || !end) || !start.isValid || !end.isValid){
            start = DateTime.fromMillis(req.beginDate.ts).setZone('UTC-0');
            end = DateTime.fromMillis(req.endDate.ts).setZone('UTC-0');
        }
        const s: any = {}
        setDateBounds({min: start, max: end});
        s.min = start;
        s.max = end;
        setSelectedDates(s)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location.state, req.beginDate, req.endDate]);

    const peakShavedDemandData = useMemo(() =>{
        if(!selectedDates)return;
        if(!chargeLocation)return;

        const arr: Array<any> = [];
        for(const k in chargeLocation.todPeakSmoothedSummaries){
            chargeLocation.todPeakSmoothedSummaries[k].dateKey = k;
            const ts = DateTime.fromISO(chargeLocation.todPeakSmoothedSummaries[k].localStart).toUTC();
            if(ts.ts >= selectedDates.min && ts.ts <= selectedDates.max)arr.push(chargeLocation.todPeakSmoothedSummaries[k]);
        }
        return arr;
    }, [chargeLocation, selectedDates]);

    const smartChargeDemandData = useMemo(() =>{
        if(!selectedDates)return;
        if(!chargeLocation)return;

        const smartChargeDemandData: Array<any> = [];
        for(const k in chargeLocation.todSmartSummaries){
            chargeLocation.todSmartSummaries[k].dateKey = k;
            const ts = DateTime.fromISO(chargeLocation.todSmartSummaries[k].localStart).toUTC();
            if(ts >= selectedDates.min && ts <= selectedDates.max)smartChargeDemandData.push(chargeLocation.todSmartSummaries[k]);
        }
        return smartChargeDemandData;
    }, [chargeLocation, selectedDates]);

    const demandData = useMemo(()=>{
        if(!selectedDates)return;
        if(!chargeLocation)return;
  
        const demandData: Array<any> = [];
        for(const k in chargeLocation.todSummaries){
            chargeLocation.todSummaries[k].dateKey = k;
            const ts = DateTime.fromISO(chargeLocation.todSummaries[k].localStart).toUTC();
            if(ts >= selectedDates.min && ts <= selectedDates.max)demandData.push(chargeLocation.todSummaries[k]);
        }

        return demandData;

    },[chargeLocation, selectedDates]);

    function handleDateChange(type: string, e: DateTime) {
        setSelectedDates({
            min: type === "min" ? e : selectedDates.min,
            max: type === "max" ? e : selectedDates.max
        });
    }

    const onChargeTypeChange = (option: React.SetStateAction<OptionType>) => {
        setSelectedChargeType(option);
    };

    const [statBoxesState, dispatchStatBoxes] = useReducer((state: StatBoxesType, action: Partial<StatBoxesType>) => ({
        ...state,
        ...action
    }), {
        vehiclesProjectedToCharge: {
            value: "",
            subValue: ""
        },
        vehiclesWithPassingEvScore: {
            value: ""
        },
        highestPeakDemand: {
            value: "",
            subValue: "kW"
        },
        level2ChargingPortsNeeded: {
            value: ""
        },
        dcfcChargingPortsNeeded: {
            value: ""
        },
        totalLocationCharging: {
            value: "",
            subValue: "%"
        }
    });

    useEffect(() => {
        dispatchStatBoxes({
            vehiclesProjectedToCharge: {
                value: `${inBoundsChargingVehicles}`,
                subValue: `/${totalVehicleCount ?? 0}`,
                caption: "Vehicles Projected to Charge/Total Vehicles in Fleet"
            }
        })
    }, [chargeLocation, inBoundsChargingVehicles, totalVehicleCount]);

    useEffect(() => {
        dispatchStatBoxes({
            vehiclesWithPassingEvScore: {
                value: `${inBoundsVclsWithRec}`,
                caption: "Vehicles Recommended for Replacement with an EV"
            }
        })
    }, [chargeLocation, inBoundsVclsWithRec]);

    useEffect(() => {
        let peak = 0;
        demandData?.forEach((d: any) => {
            d.activity.forEach((a: number) => {
                //cl worker generate 100 activity blocks on daylight saving
                //switch day in Nov. This handles that.
                //TODO: fix completely in cl-worker
                if(!isNaN(a))peak = Math.max(peak, a);
            })
        });
        dispatchStatBoxes({
            highestPeakDemand: {
                value: `${peak}`,
                subValue: "kW",
                caption: "Highest Peak Demand at Same Time"
            }
        })
    }, [demandData]);

    useEffect(() => {
        const loc = chargeLocations.get(selectedChargeLocation);
        if(!loc.summary) return;
        const locationDrawHistogram = new Array(5);
        locationDrawHistogram.fill(0);

        // This logic is somewhat duplicated from the charge worker
        // We record the blocks using a TS like key, so we can determine what blocks lie within the inner bounds
        for(const cb in loc.summary?.chargeBlocks) {
            const epoch = parseInt(cb)*MINUTES_PER_BLOCK*60*1000; // Charge block key is in 15 minute increments --> 15 mins per block * 60 secs per min * 1000 millis per sec
            if(epoch >= selectedDates.min && epoch <= selectedDates.max) {
                // For all blocks within the inner date bounds, update the histogram with the max draw for all ports
                locationDrawHistogram[0] = Math.max(locationDrawHistogram[0], loc.summary.chargeBlocks[cb].drawHistogram[0]);
                locationDrawHistogram[1] = Math.max(locationDrawHistogram[1], loc.summary.chargeBlocks[cb].drawHistogram[1]);
                locationDrawHistogram[2] = Math.max(locationDrawHistogram[2], loc.summary.chargeBlocks[cb].drawHistogram[2]);
                locationDrawHistogram[3] = Math.max(locationDrawHistogram[3], loc.summary.chargeBlocks[cb].drawHistogram[3]);
                locationDrawHistogram[4] = Math.max(locationDrawHistogram[4], loc.summary.chargeBlocks[cb].drawHistogram[4]);

            }
        }

        dispatchStatBoxes({
            level2ChargingPortsNeeded: {
              value: `${locationDrawHistogram[0]}/${locationDrawHistogram[1]}/${locationDrawHistogram[2]}`,
              caption: `L2 Ports Needed`,
              subCaption: '(7kW/11kW/20kW)'
            }
          })
          dispatchStatBoxes({
            dcfcChargingPortsNeeded: {
              value: `${locationDrawHistogram[3]}/${locationDrawHistogram[4]}`,
              caption: 'DCFC Ports Needed',
              subCaption: '(50kW/100kW)'
            }
          })
    }, [chargeLocations, selectedChargeLocation, selectedDates]);

    useEffect(() => {
        let ratio = 0;
        if(atLocationChargingKwh + notAtLocationChargingKwh > 0){
            ratio = atLocationChargingKwh / (atLocationChargingKwh + notAtLocationChargingKwh)*100;
            ratio = Math.round(ratio);
        }
        
        dispatchStatBoxes({
            totalLocationCharging: {
                value: `${ratio}`,
                subValue: "%",
                caption: "Charging Projected at This Location"
            }
        })
    }, [atLocationChargingKwh,notAtLocationChargingKwh]);

    useMemo(() => {
        if(!chargeLocation || !chargeLocation.charge_events) return
        if(chargeLocation && chargeLocation.chargingVehiclesVins && dateBounds){
            chargeLocation.chargingVehiclesVins.forEach((v: string) => {
                const vcl = chargeLocation.vehicleResults.find((b) => b.vin === v);
                const ces = chargeLocation.charge_events.filter((ce: any) => ce?.vin === v);
                let duration = 0;
                let count = 0;
                ces.forEach((ce: any)=>{
                    const ts = DateTime.fromISO(`${ce.utc_start}Z`).toUTC();
                    if(ts >= dateBounds.min && ts <= dateBounds.max){
                        const ts1 = DateTime.fromISO(`${ce.utc_stop}Z`).toUTC();
                        duration += (ts1.ts - ts.ts)/1000/3600;
                        count++;
                    }
                });
                vcl.averageChargeHours = duration/count;
            })
        }
    }, [chargeLocation, dateBounds]);

    //This is duplicative of work that is done in the cl-worker and a bad pattern
    //we're putting this in place temporarily, given time constraints,
    //pending a larger refactoring effort
    const filterVehicleData = useCallback((mutableVehicleData: any) => {

        // for each loop on the vehicles charge events to get
        // here and away kwh and avg hours of charging vals for dateBounds
        // this is now also an overloaded function and handles vcls plus charge count and home/non-home sums
        let chargingVcls = 0;
        let vclsWithRec = 0;
        let totalHereKwh = 0;
        let totalAwayKwh = 0;
        mutableVehicleData.forEach((vcl: any) => {
            const locId = chargeLocation.uuid;
            //adding one handles the zero index...
            const days = 1+(selectedDates.max.ts - selectedDates.min.ts)/1000/86400;
            let hereKwh = 0;
            let awayKwh = 0;
            let chargeHours = 0;
            let date_kwh_totals = new Map(); // hash of the kwh totals for each date
            if (selectedVins.includes(vcl.vin)) {
                vcl.charge_events?.forEach((ce: any) => {
                    const ls = DateTime.fromISO(`${ce.utc_start}Z`).toUTC();
                    if(ls >= selectedDates.min && ls <= selectedDates.max){
                        if(ce.parking_loc === locId)hereKwh += ce.kwh;
                        else awayKwh += ce.kwh;
                        const le = DateTime.fromISO(`${ce.utc_stop}Z`).toUTC();
                        const duration = le.ts - ls.ts;
                        chargeHours += (duration/1000/60/60); 
                        const bm = ce.local_start.split(' ')[0]
                        if(ce.parking_loc === locId) {
                            if (date_kwh_totals.has(bm)) { // add the event's kwh to the total for the day if it's already in the map
                            date_kwh_totals.set(bm, date_kwh_totals.get(bm) + ce.kwh);
                            } else {
                                date_kwh_totals.set(bm, ce.kwh);
                            }
                        }
                    }
                });
                totalHereKwh += hereKwh;
                totalAwayKwh += awayKwh;
                vcl.averageChargeHours = chargeHours/days;
                vcl.period_at_location_kwh = hereKwh;
                vcl.period_not_at_location_kwh = awayKwh;
                if(chargeHours > 0)chargingVcls++;
                if(chargeHours > 0 && vcl.is_ev_recommendation === true)vclsWithRec++;

                let values = Array.from(date_kwh_totals.values());
                let sum = values.reduce((acc, val) => acc + val, 0);
                vcl.loc_max_daily_kwh = values.reduce((acc, val) => Math.max(acc, val), 0);
                vcl.loc_avg_daily_kwh = values.length > 0 ? sum / values.length : 0;
            }
        });

        setInBoundsChargeVehicles(chargingVcls);
        setInBoundsVclsWithRec(vclsWithRec);
        setAtLocationChargingKwh(totalHereKwh);
        setNotAtLocationChargingKwh(totalAwayKwh);
        return mutableVehicleData;
    }, [chargeLocation, selectedDates, selectedVins])

    const formattedVehicleData = useMemo(() => {
        if(!chargeLocation) {return null}
        let mutatableVehicleData = JSON.parse(JSON.stringify(chargeLocation.vehicleResults));

        // Filter vehicle data based on controls
        mutatableVehicleData = filterByCategory(mutatableVehicleData, category);
        mutatableVehicleData = filterByVehicleClass(mutatableVehicleData, vehicleClasses);
        mutatableVehicleData = filterByGroups(mutatableVehicleData, groups);

        mutatableVehicleData.forEach((vcl: any) => {
            vcl.asset_id = vehicleIdDisplay(vcl)
        });
        return filterVehicleData(mutatableVehicleData);
    },[chargeLocation, filterVehicleData, category, vehicleClasses, groups]);

    return (
        <>
            <S.PageLayout >
                <S.ContentWrapper>
                <S.PageHeader ref={navRef}>
                    <S.DropdownMenu onClick={() => {setNavOpen(!navOpen)}}>
                        {viewMode === ViewMode.Monthly ? "Monthly Peak kW Demand" : "Daily Demand"}
                        <S.DownArrowWrapper>
                            <DownArrow/>
                        </S.DownArrowWrapper>
                    </S.DropdownMenu>
                    {navOpen &&
                        <S.DropdownOptions onClick={() => setNavOpen(false)}>
                            <S.OptionLink
                                to={{
                                    pathname: (viewMode === ViewMode.Monthly ? `/ezio/kw-demand-daily/${selectedChargeLocation}` : `/ezio/kw-demand-monthly/${selectedChargeLocation}`), 
                                    state: {lastDailyDemandDateBounds: (viewMode !== ViewMode.Monthly ? {beginDate: selectedDates.min, endDate: selectedDates.max} : (history.location.state as any)?.lastDailyDemandDateBounds)}
                                }} 
                            >
                                {viewMode === ViewMode.Monthly ? "Daily Demand" : "Monthly Peak Demand"}
                            </S.OptionLink>
                        </S.DropdownOptions>
                    }
                    <S.LocationPageAddress id="location-address-testid">{chargeLocations.get(selectedChargeLocation).nickname ? chargeLocations.get(selectedChargeLocation).nickname : chargeLocations.get(selectedChargeLocation).address}</S.LocationPageAddress>
                </S.PageHeader>
                <S.DetailsControls>
                    <S.InputWrapper>
                        <S.ControlsLabel>Charge Type</S.ControlsLabel>
                        <ChargeTypeDropdown selectedChargeType={selectedChargeType} onChange={onChargeTypeChange}/>
                    </S.InputWrapper>
                    <S.InputWrapper>
                        <S.ControlsLabel>Time Period</S.ControlsLabel>
                        {viewMode === ViewMode.Monthly &&
                        <S.DateSelectorContainer id="container-test">
                            <DateSelectors
                                selectorPosition="interior"
                                dateBounds={{min: req.beginDate, max: req.endDate}}
                                selectedDates={selectedDates}
                                handleChange={handleDateChange}
                                userSettings={userSettings}
                            />
                        </S.DateSelectorContainer>
                        }
                        {viewMode === ViewMode.TimeOfDay &&
                        <S.DateSelectorContainer>
                            <DateSelectors
                                selectorPosition="interior"
                                dateBounds={{min: req.beginDate, max: req.endDate}}
                                selectedDates={selectedDates}
                                handleChange={handleDateChange}
                                userSettings={userSettings}
                            />
                        </S.DateSelectorContainer>
                        }
                        </S.InputWrapper>
                        

                    <S.LocationSummaryNavigation to={chargeLocation ? `/ezio/locations/${chargeLocation.uuid}` : `/ezio/locations/list`}>
                        Location Summary
                    </S.LocationSummaryNavigation>
                </S.DetailsControls>
                <S.StatBoxRow>
                    <S.StatBoxContainer>
                        {/* evRecommendationCount / vehicleCount of location  */}
                        {showVehicleCountToolTip &&
                            <S.ToolTipWrapper cursorCoords={{x: 150, y: -80}}
                                onMouseEnter={(e) => {handleShowVehicleCountToolTip(true, e)}}
                                onMouseLeave={(e) => {handleShowVehicleCountToolTip(false, e)}}
                            >
                            <S.ToolTipText>
                            This is the number of vehicles projected to charge at this location based on the selected filters, compared to the total number of vehicles in the fleet. Vehicles may be included in these figures that currently do not use this location as their primary parking location.
                            </S.ToolTipText>
                            </S.ToolTipWrapper>
                        }
                        {chargeLocation &&
                            <StatView 
                                valueId={"projected-to-charge-testid"}
                                subValueId={"projected-to-charge-total-testid"}
                                captionId={"projected-to-charge-caption-testid"}
                                values={statBoxesState.vehiclesProjectedToCharge}
                                hoverHighlight={false} 
                                onMouseEnter={(e) => {handleShowVehicleCountToolTip(true, e)}}
                                onMouseLeave={(e) => {handleShowVehicleCountToolTip(false, e)}}
                            />
                        }
                    </S.StatBoxContainer>
                    <S.StatBoxContainer>
                        {/* evRecommendationCount / vehicleCount of location  */}
                        <StatView
                            valueId={"recommended-vehicle-count-testid"}
                            captionId={"recommended-vehicle-caption-testid"}
                            values= {statBoxesState.vehiclesWithPassingEvScore} 
                            hoverHighlight={false} />
                    </S.StatBoxContainer>
                    <S.StatBoxContainer>
                        <StatView 
                            valueId={"highest-peak-demand-testid"}
                            subValueId={"highest-peak-demand-subvalue-testid"}
                            captionId={"highest-peak-demand-caption-testid"}
                            values={statBoxesState.highestPeakDemand} 
                            hoverHighlight={false} 
                        />
                    </S.StatBoxContainer>
                    {/* Same as max EVs for now */}
                    <S.StatBoxContainer>
                    {showL2PortCountToolTip &&
                        <S.ToolTipWrapper cursorCoords={{y: -40, x: 150}}
                            onMouseEnter={(e) => {handleShowPortCountToolTip(true, e, 'l2')}}
                            onMouseLeave={(e) => {handleShowPortCountToolTip(false, e, 'l2')}}
                        >
                        <S.ToolTipText>
                        These numbers are for the for the time period displayed at the top of the view, not the monthly drill down.
                        </S.ToolTipText>
                        </S.ToolTipWrapper>
                    }
                        <StatView
                            valueId={"l2-port-count-testid"}
                            captionId={"l2-port-count-caption-testid"}
                            subCaptionId={"l2-port-count-subcaption-testid"}
                            values={statBoxesState.level2ChargingPortsNeeded} 
                            hoverHighlight={false} 
                            onMouseEnter={(e) => {handleShowPortCountToolTip(true, e, 'l2')}}
                            onMouseLeave={(e) => {handleShowPortCountToolTip(false, e, 'l2')}}
                            />
                    </S.StatBoxContainer>
                    <S.StatBoxContainer>
                    {showPortCountToolTip &&
                        <S.ToolTipWrapper cursorCoords={{y: -40, x: 150}}
                            onMouseEnter={(e) => {handleShowPortCountToolTip(true, e, 'dc')}}
                            onMouseLeave={(e) => {handleShowPortCountToolTip(false, e, 'dc')}}
                        >
                        <S.ToolTipText>
                        These numbers are for the for the time period displayed at the top of the view, not the monthly drill down.
                        </S.ToolTipText>
                        </S.ToolTipWrapper>
                    }
                        <StatView
                            valueId={"dcfc-port-count-testid"}
                            captionId={"dcfc-port-count-caption-testid"}
                            subCaptionId={"dcfc-port-count-subcaption-testid"}
                            values={statBoxesState.dcfcChargingPortsNeeded} 
                            hoverHighlight={false} 
                            onMouseEnter={(e) => {handleShowPortCountToolTip(true, e, 'dc')}}
                            onMouseLeave={(e) => {handleShowPortCountToolTip(false, e, 'dc')}}
                            />
                    </S.StatBoxContainer>
                    <S.StatBoxContainer>
                        {showParkingPercentageToolTip &&
                            <S.ParkingToolTipWrapper cursorCoords={{y: -90, x: -20}}
                                onMouseEnter={(e) => {handleShowParkingPercentageToolTip(true, e)}}
                                onMouseLeave={(e) => {handleShowParkingPercentageToolTip(false, e)}}
                            >
                            <S.ToolTipText>
                                For the vehicles that park at this location, this is the percentage of their charging that will occur at this location. If this is below 100%, some of the vehicles are projected to charge at other locations, too.
                            </S.ToolTipText>
                            </S.ParkingToolTipWrapper>
                        }
                        <StatView                            
                            valueId={"charging-percentage-testid"}
                            captionId={"charging-percentage-caption-testid"}
                            values={statBoxesState.totalLocationCharging}
                            onMouseEnter={(e) => {handleShowParkingPercentageToolTip(true, e)}}
                            onMouseLeave={(e) => {handleShowParkingPercentageToolTip(false, e)}}
                            />
                    </S.StatBoxContainer>
                </S.StatBoxRow>
                </S.ContentWrapper>

                {chargeLocation &&
                <S.GraphTableWrapper>
                    {viewMode === ViewMode.Monthly &&
                        <S.GraphWrapper>
                            <S.TableTitle>Monthly kW Demand</S.TableTitle>
                            <S.GraphSubtitle>
                                This graph shows the projected peak demand from charging at this location.
                            </S.GraphSubtitle>
                            {/* Left bar  */}
                            {chargeLocation?.monthlyPeaks &&
                                < GraphMonthly
                                    monthlyPeaks={chargeLocation?.monthlyPeaks}
                                    displayTitle={false}
                                />
                            }
                            {!chargeLocation?.monthlyPeaks &&
                                <S.NoDataMessage>No kW Demand Data to Display. Try adjusting filters for more results.</S.NoDataMessage>
                            }
                        </S.GraphWrapper>
                    }
                    {viewMode === ViewMode.TimeOfDay &&
                        <S.GraphWrapper>
                            <S.TableTitle>Daily kW Demand - Selected Period</S.TableTitle>
                            <S.GraphSubtitle>
                                This graph shows the projected daily demand curves for each day of the
                                selected period.
                            </S.GraphSubtitle>
                            {demandData && demandData.length > 0 &&
                                <GraphDaily
                                    demandData={demandData}
                                    smartChargeDemandData={smartChargeDemandData}
                                    peakShavedDemandData={peakShavedDemandData}
                                    dateBounds={selectedDates}
                                    selectedChargeType={selectedChargeType}
                                    userSettings={userSettings}
                                />
                            }
                            {(!demandData || demandData.length < 1) &&
                                <S.NoDataMessage>No kW Demand Data to Display. Try adjusting filters for more results.</S.NoDataMessage>
                            }
                        </S.GraphWrapper>
                    }
                    <S.TableWrapper>
                        <S.TableContainer>
                            <S.TableTitle>Vehicles</S.TableTitle>
                            <S.TableSubtitle>
                                This table lists the vehicles that are projected to charge at this location.
                            </S.TableSubtitle>
                            <div style={{position: 'relative'}}>
                            {showPrimaryParkingToolTip &&
                                <S.TableParkingToolTipWrapper cursorCoords={cursorCoords}
                                    onMouseEnter={(e) => {handleShowPrimaryParkingToolTip(true, e)}}
                                    onMouseLeave={(e) => {handleShowPrimaryParkingToolTip(false, e)}}
                                >
                                    <S.ToolTipText>
                                        This column indicates the most recent primary parking status of the specified vehicle at this location, based on our analysis of its latest 30 days of travel. Note that it does not account for date range selection.
                                    </S.ToolTipText>
                                </S.TableParkingToolTipWrapper>
                            }
                            <Table
                                columns={locationPageTableColumns}
                                data={formattedVehicleData}
                                type={"details"}
                                hoverHighlight={false}
                                defaultPrimarySort={{columnId:"willChargeWithSelectedFilters",sortDesc:true}}
                                defaultSecondarySort={{columnId:"primaryParkingLocation",sortDesc:true}}
                                defaultTertiarySort={{columnId:"chargingHours",sortDesc:true}}
                            />
                            </div>
                        </S.TableContainer>
                        <div>
                            {chargeLocation?.vehicleResults && 
                                <Suspense fallback={<div></div>}>
                                    <ExcelDownloadButton
                                        csvType={'vehicles'}
                                        beginDate={selectedDates.min}
                                        endDate={selectedDates.max}
                                        category={category}
                                        groups={groups}
                                        electrification={electrification}
                                        vehicleClasses={vehicleClasses}
                                        location={chargeLocation}
                                        db={req.dbName}
                                        columns={columnsToExcelHeaders(locationPageTableColumns)}
                                        data={rowsToData(locationPageTableColumns, formattedVehicleData, excelDataSort)}
                                        dbDisplayName={dbDisplayName}
                                        userSettings={userSettings}
                                    />
                                </Suspense>
                            }
                        </div>
                    </S.TableWrapper>
                </S.GraphTableWrapper>
                }
            </S.PageLayout>
        </>
    )
}
