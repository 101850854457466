import React, 
  { useMemo,
    useState, 
    useEffect, 
    useReducer, 
    useCallback,
    Suspense } from "react"
import * as S from "../../styles/ezio-styles/LandingPage-styles";
import { ChargeLocation } from "../swt-ezio";
import { StatView } from "@sawatchlabs/swt-components";
import {DateTime} from 'luxon';
import { Roles } from "../core-components/utility-components/UserUtils";

const ExcelDownloadButton = React.lazy(() => import("./ExcelDownloadButton"));

//Stat View Properties
const VALUE_COLOR = "#4A4A4A";
const SUB_VALUE_COLOR ="#4A4A4A";
const HOVER_EFFECT="2px 2px 15px rgba(0, 0, 0, 0.18)";
const CAPTION_COLOR="#74787D";

type LandingProps = {
  selectedLocationId: string
  forceNavigate: Function
  chargeLocations: Map<string, ChargeLocation>
  totalVehicleCount: number;
  apiUrl: string
  user: {
    userSettings: {
      currency_symbol: string
    }
    role: number
  }
  dbName: string
  noGroups: boolean
  selectedCategory: any
  settingsFullOn: boolean,
  ALL_LOCATIONS_ID: string,
  beginDate?: DateTime,
  endDate?: DateTime,
  dbDisplayName: string,
  selectedGroups: any,
  selectedClasses: any,
  selectedElectrification: number
}

type StatBubblesType = {
  vehiclesProjectedToCharge: {
    value: string,
    subValue?: string,
    caption?: string,
    subCaption?: string
  }
  vehiclesWithPassingEvScore: {
    value: string,
    subValue?: string,
    caption?: string,
    subCaption?: string
  }
  averageMonthlyEVChargingCost: {
    value: string,
    subValue?: string,
    caption?: string,
    subCaption?: string
  },
  highestPeakDemand: {
    value: string,
    subValue?: string,
    caption?: string,
    subCaption?: string
  },
  projectedChargingLocations: {
    value: string,
    subValue?: string,
    caption?: string,
    subCaption?: string
  },
  level2PortsNeeded: {
    value: string,
    subValue?: string,
    caption?: string,
    subCaption?: string
  }
  dcfcPortsNeeded: {
    value: string,
    subValue?: string,
    caption?: string,
    subCaption?: string
  }
}

export default function LandingPage({ selectedLocationId, forceNavigate, chargeLocations, selectedCategory, noGroups, totalVehicleCount, settingsFullOn, user, ALL_LOCATIONS_ID, dbName, dbDisplayName, beginDate, endDate, selectedGroups, selectedClasses, selectedElectrification}: LandingProps) {
  
  const [showVehicleCountToolTip, setShowVehicleCountToolTip] = useState<Boolean>(false);
  const [cursorCoords, setCursorCoords] = useState({x: 0, y: 0});
  const currencySymbol = user.userSettings.currency_symbol;
  const handleShowVehicleCountToolTip = (show: Boolean, mouseEvent: any) => {
    setCursorCoords({x:mouseEvent.pageX, y:mouseEvent.pageY});
    setShowVehicleCountToolTip(show);
  }

  function navigateIfValidID(id: string): void {
    if (selectedLocationId === ALL_LOCATIONS_ID) {
      return
    }
    else { forceNavigate(id) }
  }

  const [state, dispatch] = useReducer((state: StatBubblesType, action: Partial<StatBubblesType>) => ({
    ...state,
    ...action
  }), {
    vehiclesProjectedToCharge: {
      value: "",
      subValue: "",
    },
    vehiclesWithPassingEvScore: {
      value: ""
    },
    averageMonthlyEVChargingCost: {
      value: ""
    },
    highestPeakDemand: {
      value: ""
    },
    projectedChargingLocations: {
      value: ""
    },
    level2PortsNeeded: {
      value: ""
    },
    dcfcPortsNeeded: {
      value: ""
    }
  })

  const calculatePortCountDemand = useCallback(() => {
    let locationHistogram;
    if (selectedLocationId === ALL_LOCATIONS_ID) {
      // All locations --> sum port count demands across all individual locations
      locationHistogram = new Array(5);
      locationHistogram.fill(0);
      const validLocs = Array.from(chargeLocations.values()).filter((l) => l.uuid !== ALL_LOCATIONS_ID); // Locs that are not our "All Locations" loc
      validLocs.forEach((l) => {
        for(let i = 0; i < locationHistogram.length; i++) {
          locationHistogram[i] += l.drawHistogram[i];
        }
      })
    }
    else {
      // Specific location --> just grab the histo from the location records using the ID
      locationHistogram = chargeLocations.get(selectedLocationId).drawHistogram;
    }
    return locationHistogram;
  }, [chargeLocations, selectedLocationId, ALL_LOCATIONS_ID])

  //Calculations for "Vehicles in Selection/Total Vehicles"
  useEffect(() => {
    dispatch({
      vehiclesProjectedToCharge: {
        value: `${chargeLocations.get(selectedLocationId).inBoundChargingVehiclesCount}`,
        subValue: `/${totalVehicleCount}`,
        caption: "Projected to Charge/Total Vehicles in Fleet"
      }
    }
    )
  }, [chargeLocations, selectedLocationId, totalVehicleCount])

  useEffect(() => {
    dispatch({
      vehiclesWithPassingEvScore: {
        value: `${chargeLocations.get(selectedLocationId).evRecommendationCount}`,
        caption: "Vehicles Recommended for Replacement with an EV"
      }
    })
  }, [chargeLocations, selectedLocationId]);

  //Calculations for "Average Monthly EV Charging Cost"
  useEffect(() => {
    //confusingly, the "monthlyPeaks" list also has the monthly cost as "cost"
    //which one might think is the peak cost for the month, but it's the total cost for the month. 

    const loc = chargeLocations.get(selectedLocationId);
    let cost = 0;
    let count = 0;
    for(const p in loc.monthlyPeaks){cost += loc.monthlyPeaks[p].cost;count++;}

    dispatch({
      averageMonthlyEVChargingCost: {
        value: `${currencySymbol}${(cost > 0 ? (Math.round(cost/count)) : 0).toLocaleString()}`,
        caption: selectedLocationId !== ALL_LOCATIONS_ID ? "Average Monthly EV Charging Cost" : "Average Monthly EV Charging Cost at Selected Locations"
      }
    })

  }, [chargeLocations, currencySymbol, selectedLocationId, ALL_LOCATIONS_ID])
  //calculations for "Highest Peak Demand"
  useEffect(() => {
    const validLocs = Array.from(chargeLocations.values()).filter((l) => (l.uuid !== ALL_LOCATIONS_ID && l.maxVehiclesCharging > 0));
    let m = 0;
    validLocs.forEach((a)=>{for(const mp in a.monthlyPeaks){m = Math.max(m, a.monthlyPeaks[mp].peakKw)}});
    dispatch({
      highestPeakDemand: {
        value: `${m.toFixed(0)}`,
        subValue: "kW",
        caption: selectedLocationId !== ALL_LOCATIONS_ID ? "Highest Peak Demand at Same Time" : "Highest Peak Demand at Same Time at a Single Location"
      }
    })
  }, [chargeLocations, selectedLocationId, ALL_LOCATIONS_ID])
  
  //calculations for "Projected Charging Locations"
  useEffect(() => {
    const validLocs = Array.from(chargeLocations.values()).filter((l) => (l.uuid !== ALL_LOCATIONS_ID && l.maxVehiclesCharging > 0));
    dispatch({
      projectedChargingLocations: {
        value: `${validLocs.length}`,
        caption: "Projected Charging Locations"
      }
    })
  }, [chargeLocations, ALL_LOCATIONS_ID])
  //"L2 Ports Needed" & "DCFC Ports Needed" 
  useEffect(() => {
    const locationHistogram = calculatePortCountDemand();
    dispatch({
      level2PortsNeeded: {
        value: `${locationHistogram[0]}/${locationHistogram[1]}/${locationHistogram[2]}`,
        caption: `L2 Ports Needed`,
        subCaption: '(7kW/11kW/20kW)'
      }
    })
    dispatch({
      dcfcPortsNeeded: {
        value: `${locationHistogram[3]}/${locationHistogram[4]}`,
        caption: 'DCFC Ports Needed',
        subCaption: '(50kW/100kW)'
      }
    })
  }, [chargeLocations, selectedLocationId, calculatePortCountDemand, ALL_LOCATIONS_ID])

  const countMismatch = useMemo(() => {
    if(!settingsFullOn)return false;
    const r = Array.from(chargeLocations.values()).filter((l) => l.uuid !== ALL_LOCATIONS_ID);
    const i = r.reduce((p, n)=>{return p+n.chargingVehiclesCount},0);
    if(i < totalVehicleCount)return true;
    return false;
  }, [settingsFullOn, chargeLocations, totalVehicleCount, ALL_LOCATIONS_ID]);
    
  const noDataMessage = noGroups ? `You do not have any groups assigned with ${selectedCategory.label} vehicles. Try updating the category selector or reach out to your admin to be added to applicable groups` : "No Data to Display. Try adjusting filters for more results."
  const misalignedVehicleCountsMessage = `Total Vehicles and Vehicles Projected to Charge are mismatched when not all vehicles under analysis have consistent projected charge locations.`
  return (
        <>
          <S.NoDataMessage show={(chargeLocations.size === 0 || noGroups)}>{noDataMessage}</S.NoDataMessage>
          <S.StatBoxContainer>
            <S.StatBoxRow>
              <S.StatBoxLink
                to={selectedLocationId !== ALL_LOCATIONS_ID ? `/ezio/locations/${selectedLocationId}` : "/ezio/locations/list"}
                onClick={() => navigateIfValidID(selectedLocationId)}
                onMouseEnter={(e) => {handleShowVehicleCountToolTip(true, e)}}
                onMouseLeave={(e) => {handleShowVehicleCountToolTip(false, e)}}
              >
                {showVehicleCountToolTip &&
                <S.ToolTipWrapper
                  cursorCoords={cursorCoords}
                  onMouseEnter={(e) => {handleShowVehicleCountToolTip(true, e)}}
                  onMouseLeave={(e) => {handleShowVehicleCountToolTip(false, e)}}
                >
                <S.ToolTipText>
                This is the number of vehicles projected to charge based on the selected filters, compared to the total number of vehicles in the fleet.
                </S.ToolTipText>
                </S.ToolTipWrapper>
                }
                <StatView
                  valueId={"projected-to-charge-testid"}
                  subValueId={"projected-to-charge-total-testid"}
                  captionId={"projected-to-charge-caption-testid"}
                  values={state.vehiclesProjectedToCharge} 
                  valueColor={VALUE_COLOR}
                  subValueColor={SUB_VALUE_COLOR}
                  hoverEffect={HOVER_EFFECT}
                  captionColor={CAPTION_COLOR}
                  />
              </S.StatBoxLink>
              <S.StatBoxLink
                to={selectedLocationId !== ALL_LOCATIONS_ID ? `/ezio/locations/${selectedLocationId}` : "/ezio/locations/list"}
                onClick={() => navigateIfValidID(selectedLocationId)}
              >
                <StatView
                  valueId={"recommended-vehicle-count-testid"}
                  captionId={"recommended-vehicle-caption-testid"}
                  values={state.vehiclesWithPassingEvScore} 
                  valueColor={VALUE_COLOR}
                  subValueColor={SUB_VALUE_COLOR}
                  hoverEffect={HOVER_EFFECT}
                  captionColor={CAPTION_COLOR}
                />
              </S.StatBoxLink>
              <S.StatBoxLink
                to={selectedLocationId !== ALL_LOCATIONS_ID ? `/ezio/locations/${selectedLocationId}` : "/ezio/locations/list"}
                onClick={() => navigateIfValidID(selectedLocationId)}>
                <StatView 
                  valueId={"avg-monthly-charging-cost-testid"}
                  captionId={"avg-monthly-charging-cost-caption-testid"}
                  values={state.averageMonthlyEVChargingCost}
                  valueColor={VALUE_COLOR}
                  subValueColor={SUB_VALUE_COLOR}
                  hoverEffect={HOVER_EFFECT}
                  captionColor={CAPTION_COLOR}
                />
              
              </S.StatBoxLink>
              {/* Max peakKw */}
              <S.StatBoxLink to={selectedLocationId !== ALL_LOCATIONS_ID ? `/ezio/kw-demand-monthly/${selectedLocationId}` : "/ezio/locations/list"}
                onClick={() => navigateIfValidID(selectedLocationId)}>
                <StatView 
                  valueId={"highest-peak-demand-testid"}
                  subValueId={"highest-peak-demand-subvalue-testid"}
                  captionId={"highest-peak-demand-caption-testid"}
                  values = {state.highestPeakDemand}
                  valueColor={VALUE_COLOR}
                  subValueColor={SUB_VALUE_COLOR}
                  hoverEffect={HOVER_EFFECT}
                  captionColor={CAPTION_COLOR}
                />
              </S.StatBoxLink>
              {/* Count of non-empty locations */}


            </S.StatBoxRow>
            <S.StatBoxRow>
              <S.StatBoxLink to={selectedLocationId !== ALL_LOCATIONS_ID ? `/ezio/locations/${selectedLocationId}` : "/ezio/locations/list"}
                onClick={() => navigateIfValidID(selectedLocationId)}>
                <StatView 
                  valueId={"projected-charging-locations-testid"}
                  captionId={"projected-charging-locations-caption-testid"}
                  values={state.projectedChargingLocations}
                  valueColor={VALUE_COLOR}
                  subValueColor={SUB_VALUE_COLOR}
                  hoverEffect={HOVER_EFFECT}
                  captionColor={CAPTION_COLOR}
                />
              </S.StatBoxLink>

              <S.StatBoxLink to={selectedLocationId !== ALL_LOCATIONS_ID ? `/ezio/locations/${selectedLocationId}` : "/ezio/locations/list"}
                onClick={() => navigateIfValidID(selectedLocationId)}>
                <StatView 
                  valueId={"l2-port-count-testid"}
                  captionId={"l2-port-count-caption-testid"}
                  subCaptionId={"l2-port-count-subcaption-testid"}
                  values={state.level2PortsNeeded}
                  valueColor={VALUE_COLOR}
                  subValueColor={SUB_VALUE_COLOR}
                  hoverEffect={HOVER_EFFECT}
                  captionColor={CAPTION_COLOR}
                />

              </S.StatBoxLink>

              <S.StatBoxLink to={selectedLocationId !== ALL_LOCATIONS_ID ? `/ezio/locations/${selectedLocationId}` : "/ezio/locations/list"}
                onClick={() => navigateIfValidID(selectedLocationId)}>
                <StatView 
                  valueId={"dcfc-port-count-testid"}
                  captionId={"dcfc-port-count-caption-testid"}
                  subCaptionId={"dcfc-port-count-subcaption-testid"}
                  values={state.dcfcPortsNeeded}
                  valueColor={VALUE_COLOR}
                  subValueColor={SUB_VALUE_COLOR}
                  hoverEffect={HOVER_EFFECT}
                  captionColor={CAPTION_COLOR}
                />

              </S.StatBoxLink>
            </S.StatBoxRow>
            <S.CountMismatchMessage show={settingsFullOn && countMismatch}><sup>*</sup>{misalignedVehicleCountsMessage}</S.CountMismatchMessage>
          </S.StatBoxContainer >
          {user.role >= Roles.SuperAdmin &&
          <div data-testid='superAdminExcelDownload-testid'>
            <Suspense fallback={<div></div>}>
              <ExcelDownloadButton
                 beginDate={beginDate}
                 endDate={endDate}
                 db={dbName}
                 csvType={'superAdminExcelDownload'}
                 data={Array.from(chargeLocations).map((cl) => cl[1])}
                 columns={[]}
                 dbDisplayName={dbDisplayName}
                 userSettings={user.userSettings}
                 buttonLabel={'Download Super Admin Excel'}
                 groups={selectedGroups}
                 vehicleClasses={selectedClasses}
                 electrification={selectedElectrification}
                 category={selectedCategory}
                 selectedLocationId={selectedLocationId}
              />
            </Suspense>
          </div>
}
        </>
  )
}

