import { LocationLink } from "../../styles/ezio-styles/VehiclesPage-styles";

const primaryParkingSort = (rowA, rowB, columnId) => {
    // Enumerate sort order for primary parking status column sort
    const StatusPriority = {
        Current: 1,
        Past: 2,
        No: 3
    };

    const valueA = rowA.values[columnId];
    const valueB = rowB.values[columnId];
    
    const priorityA = StatusPriority[valueA];
    const priorityB = StatusPriority[valueB];

    // Compare priorities to determine sort order
    const sortOrder = priorityB - priorityA;
    return sortOrder;
};

const numericSort = (rowA, rowB, colId) => {
    let a = (rowA.values[colId] === '-') ? -1 : rowA.values[colId];
    let b = (rowB.values[colId] === '-') ? -1 : rowB.values[colId];
    return a > b ? 1 : -1;
}

export const locationPageTableColumns = [
    { Header: "Asset ID", simpleHeader: "Asset ID", id: "assetId", accessor: (row: any) => row.asset_id, sortType: "basic", Filter: IDColumnFilter, width: 181, showToolTip: (Boolean, object) => {}, showInInterface: true, showInDownload: true},
    { Header: "Year", simpleHeader: "Year", id: "vehicleYear", accessor: (row: any) => row.year, sortType: "basic", Filter: <div />, width: 70, showInInterface: true, showInDownload: true },
    { Header: "Make", simpleHeader: "Make", id: "vehicleMake", accessor: (row: any) => row.make, sortType: "basic", Filter: <div />, width: 140, showInInterface: true, showInDownload: true },
    { Header: "Model", simpleHeader: "Model", id: "vehicleModel", accessor: (row: any) => row.model, sortType: "basic", Filter: <div />, width: 150, showInInterface: true, showInDownload: true },
    { id: "willChargeWithSelectedFilters", accessor: (row: any) => formatChargingStatus(row.averageChargeHours), sortType: "basic", Filter: <div />, showInInterface: false, showInDownload: false },
    { Header: <div>Charging Hours<br />(Avg per Day)</div>, simpleHeader: "Charging Hours (Avg per Day)", id: "chargingHours", accessor: (row: any) => formatAvgChargingHours(row.averageChargeHours), Filter: <div />, showInInterface: true, showInDownload: true},
    { Header: "Max Daily Charge (kWh)", simpleHeader: "Max Daily Charge (kWh)", id: "maxDailyKwh", accessor: (row: any) => willChargeWithSelectedFilters(row.averageChargeHours) ? Math.round(row.loc_max_daily_kwh) : "-", Filter: <div />, showInInterface: true, showInDownload: true},
    { Header: "Avg Daily Charge (kWh)", simpleHeader: "Avg Daily Charge (kWh)", id: "avgDailyKwh", accessor: (row: any) => willChargeWithSelectedFilters(row.averageChargeHours) ? Math.round(row.loc_avg_daily_kwh) : "-", Filter: <div />, showInInterface: true, showInDownload: true},
    { Header: <div>Charger <br /> Level</div>, simpleHeader: "Charger Level", id: "chargerLevel", accessor: (row: any) => formatChargerLevel(row.default_kw_draw), sortType: "basic", Filter: <div />, width: 100, showInInterface: true, showInDownload: true },
    { Header: "Charger kW Draw", simpleHeader: "Charger kW Draw", id: "kwDraw", accessor: (row: any) => row.default_kw_draw, sortType: "basic", Filter: <div />, width: 100, showInInterface: true, showInDownload: true },
    { Header: "Vehicle Class", simpleHeader: "Vehicle Class", id: "vehicleClass", accessor: (row: any) => row.vehicle_class, sortType: "basic", Filter: <div />, width: 90, showInInterface: true, showInDownload: true },
    { Header: "EV Recommendation", simpleHeader: "EV Recommendation", id: "evRec", accessor: (row: any) => formatEvRec(row), sortType: "basic", Filter: <div />, showInInterface: true, showInDownload: true },
    { Header: "Primary Parking Location", simpleHeader: "Primary Parking Location", id: "primaryParkingLocation", accessor: (row: any) => formatHomebaseStatus(row), sortType: primaryParkingSort, Filter: <div />, showToolTip: (Boolean, object) => {}, showInInterface: true, showInDownload: true },
    { Header: "Onsite Charging (kWh)", simpleHeader: "Onsite Charging (kWh)", id: "chargingAtLocation", accessor: (row: any) => willChargeWithSelectedFilters(row.averageChargeHours) ? Math.round(row.period_at_location_kwh) : "-", sortType: numericSort, Filter: <div />, showInInterface: true, showInDownload: true },
    { Header: "Offsite Charging (kWh)", simpleHeader: "Offsite Charging (kWh)", id: "chargingNotAtLocation", accessor: (row: any) => willChargeWithSelectedFilters(row.averageChargeHours) ? Math.round(row.period_not_at_location_kwh) : "-", sortType: numericSort, Filter: <div />, showInInterface: true, showInDownload: true }
];

export const vehiclesPageTableColumns = (forceNavigate) => [
    { Header: "Asset ID", simpleHeader: "Asset ID", id: "assetId", accessor: (row: any) => row.asset_id, sortType: "basic", Filter: IDColumnFilter, width: 181, showToolTip: (Boolean, object) => {}, showInInterface: true, showInDownload: true },
    { Header: "Year", simpleHeader: "Year", id: "vehicleYear", accessor: (row: any) => row.year, sortType: "basic", Filter: <div />, width: 70, showInInterface: true, showInDownload: true },
    { Header: "Make", simpleHeader: "Make", id: "vehicleMake", accessor: (row: any) => row.make, sortType: "basic", Filter: <div />, width: 120, showInInterface: true, showInDownload: true },
    { Header: "Model", simpleHeader: "Model", id: "vehicleModel", accessor: (row: any) => row.model, sortType: "basic", Filter: <div />, width: 110, showInInterface: true, showInDownload: true },
    { Header: "Vehicle Class", simpleHeader: "Vehicle Class", id: "vehicleClass", accessor: (row: any) => row.vehicle_class, sortType: "basic", Filter: <div />, width: 90, showInInterface: true, showInDownload: true },
    { Header: "EV Recommendation", simpleHeader: "EV Recommendation", id: "evRec", accessor: (row: any) => row.is_ev_recommendation ? "Yes" : "No", sortType: "basic", Filter: <div />, width: 140, showInInterface: true, showInDownload: true},
    { Header: "Primary Parking Location", simpleHeader: "Primary Parking Location", id: "primaryParkingLoc", accessor: (row: any) => row.ezio_parking_loc_string, sortType: "basic", Filter: <div />, showToolTip: (Boolean, object) => {}, width: 250, showInInterface: true, showInDownload: true,
    Cell: (cell: any) => {return cell.row.original.parking_loc_uuid ? (<LocationLink to={`/ezio/locations/${cell.row.original.parking_loc_uuid}`} onClick={() => forceNavigate(cell.row.original.parking_loc_uuid)}>{cell.value}</LocationLink>) : "-"}
    }
];

function IDColumnFilter({ column: { filterValue, setFilter } }: any) {
    return (
        <input
            className="swt-ezio-search-input"
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Search`}
        />
    );
}

function formatHomebaseStatus(data: any) {
    if(data.is_past_homebase) {
        return 'Past'
    } else if (data.is_homebase_location) {
        return 'Current'
    } else {
        return 'No'
    }
}

function formatChargerLevel(draw: number) {
    if(draw>20){
        return ('DC Fast')
    } else{
        return ('Level 2')
    }
}

export function formatChargingStatus(hours: number) {
    return (hours > 0).toString();
}

function willChargeWithSelectedFilters(hours: number) {
    return hours > 0;
}

function formatEvRec(row) {
    if(willChargeWithSelectedFilters(row.averageChargeHours)) return row.is_ev_recommendation ? "Yes" : "No";
    else return "-";
}

function formatAvgChargingHours(hours: number) {
    // If the charging hours value is non-zero, round the value up to the nearest tenth.
    // This prevents a previously observed situation where the charging hours value would be displayed as 0.0 (due to the use of .toFixed(1)) but the actual kWh at/not at location would still be greater than 0.
    return (hours > 0) ? Math.ceil(hours * 10) / 10 : "Not Charging";
}

interface Vcl{
    asset_id: string | null,
    user_defined_vin: string | null,
    vin: string | null
}

export function vehicleIdDisplay (vcl: Vcl ) {
    if(vcl) {
    if (vcl.asset_id && vcl.asset_id !== 'null' && vcl.asset_id.toLowerCase() !== 'none') return vcl.asset_id
    if (vcl.user_defined_vin && vcl.user_defined_vin !== 'null') return vcl.user_defined_vin
    if (vcl.vin) return vcl.vin
    }
  }