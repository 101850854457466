// Helper function to filter by category
export const filterByCategory = (results: any[], category: string) => {
    if (category === "Light Duty") {
        return results.filter(d => d.is_ld);
    }
    if (category === "Medium and Heavy Duty") {
        return results.filter(d => !d.is_ld);
    }
    return results;
};

// Helper function to filter by vehicle class
export const filterByVehicleClass = (results: any[], vehicleClasses: string[]) => {
    if (vehicleClasses[0] === "All Classes") return results;
    return results.filter(r => vehicleClasses.includes(r.vehicle_class));
};

// Helper function to filter by groups
export const filterByGroups = (results: any[], groups: any[]) => {
    const allGroupVins = groups.flatMap(group => 
        group.vehicles.map((vehicle: any) => vehicle.vin)
    );

    // Remove duplicates from allGroupVins
    const uniqueGroupVins = Array.from(new Set(allGroupVins));

    return results.filter(result => uniqueGroupVins.includes(result.vin));
};