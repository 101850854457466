import React, { useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import * as S from "../../styles/ezio-styles/Header-styles"
import useOutsideAlerter from "../../utils/ezio-utils/useOutsideAlerter"
import { ReactComponent as DownArrow } from "../../images/navdownarrow-10px-12px.svg"

type HeaderProps = {
    displayName: string | null
}

export default function Header({ displayName } : HeaderProps) {

    const [locationsOpen, setLocationsOpen] = useState(false)
    // const [adminOpen, setAdminOpen] = useState(false)
    
    const route = useLocation();
    const locationsRef = useRef(null)
    // const adminRef = useRef(null)
    useOutsideAlerter(locationsRef, () => setLocationsOpen(false))
    // useOutsideAlerter(adminRef, () => setAdminOpen(false))
    return (
        <S.HeaderContainer>
            <S.LHSContentWrapper>
                <S.LogoContainer>
                    <S.LogoLink to="/ezio" />
                </S.LogoContainer>
                <S.HeaderAndFleetWrapper>
                <S.FleetNameContainer>
                    {displayName}
                </S.FleetNameContainer>
                <S.NavLinkList>
                    <S.NavLinkListItem>
                        <S.NavLink id="summary-navlink-testid" to="/ezio" selected={route.pathname === '/ezio'}>Summary</S.NavLink >
                    </S.NavLinkListItem>
                    <S.NavLinkListItem id="locations-navlink-testid" ref={locationsRef} >
                        <S.DropdownMenu
                            onClick={() => {setLocationsOpen(!locationsOpen)}}
                            selected={route.pathname.includes("/locations") || route.pathname.includes("/kw-demand")}
                        >
                            Locations
                        </S.DropdownMenu>
                        <S.DownArrowWrapper>
                            <DownArrow />
                        </S.DownArrowWrapper>
                        {locationsOpen &&
                            <S.LocationsDropdownOptions onClick={() => setLocationsOpen(false)}>
                                <S.OptionLink to="/ezio/locations/list">
                                    List
                                </S.OptionLink>
                                <S.OptionLink to="/ezio/locations/map">
                                    Map
                                </S.OptionLink>
                            </S.LocationsDropdownOptions>
                        }
                    </S.NavLinkListItem>
                    <S.NavLinkListItem>
                        <S.NavLink id="vehicles-navlink-testid" to="/ezio/vehicles" selected={route.pathname === '/ezio/vehicles'}>Vehicles</S.NavLink >
                    </S.NavLinkListItem>
                    <S.NavLinkListItem>
                        <S.NavLink id="about-navlink-testid"  to="/ezio/about" selected={route.pathname === '/ezio/about'}>About</S.NavLink >
                    </S.NavLinkListItem>
                </S.NavLinkList>
                </S.HeaderAndFleetWrapper>
            </S.LHSContentWrapper>
        </S.HeaderContainer>
    )
}

// NOTE this is navigation for 'Admin' drop down
/* <S.NavLinkListItem ref={adminRef}>
<S.DropdownMenu onClick={() => {
    setAdminOpen(!adminOpen)
    setLocationsOpen(false)
}}>Admin<S.DownArrowWrapper>
        <DownArrow />
    </S.DownArrowWrapper></S.DropdownMenu>
{adminOpen &&
    <S.AdminDropdownOptions onClick={() => setAdminOpen(false)} >
        <S.OptionLink to="/ezio/select-locations">
            Select Locations
        </S.OptionLink>
        <S.OptionLink to="/ezio/select-vehicles">
            Select Vehicles
        </S.OptionLink>
        <S.OptionLink to="/ezio/edit-location-details">
            Edit Location Details
        </S.OptionLink>
        <S.OptionLink to="/ezio/join-locations">
            Join Locations
        </S.OptionLink>
        <S.OptionLink to="/ezio/separate-locations">
            Separate Locations
        </S.OptionLink>
    </S.AdminDropdownOptions>
}
</S.NavLinkListItem> */