import { ghgConversionForSummary } from './utils/ConformUnits'

export function getFuelType(v){
    if(v.is_bev) return 'BEV';
    if(v.is_phev) return 'PHEV';
    if(v.is_diesel) return 'Diesel';
    if(v.is_cng) return 'CNG';
    // may want to switch this to rely on is_gasoline flag btu for now
    // it is a catch-all backup
    if(!v.is_bev && !v.is_phev) return 'Gasoline';
    return 'Unknown';
  }

export function decideVinDisplay (vcl) {
  if(typeof vcl.user_defined_vin === "undefined" || vcl.user_defined_vin === null || vcl.user_defined_vin === 'null' || !vcl.user_defined_vin) {
    return vcl.vin
  } else {
    return vcl.user_defined_vin
  }
}

export function vehicleIdDisplay (vcl) {
  if(vcl) {
  if (vcl.asset_id && vcl.asset_id !== 'null' && vcl.asset_id.toLowerCase() !== 'none') return vcl.asset_id
  if (vcl.user_defined_vin && vcl.user_defined_vin !== 'null') return vcl.user_defined_vin
  if (vcl.vin) return vcl.vin
  }
}

export function ymmDisplay(vcl) {
  if(!vcl) return
 const year = (parseInt(vcl.year) <= 1970) ? '' : vcl.year
 const make = (vcl.make && vcl.make !== 'null') ? vcl.make : ''
 const model = (vcl.model && vcl.model !== 'VIN Decoding Error' && vcl.model !== 'null') ? vcl.model : ''
 if (!year && !make && !model) {
   return null
 } else {
   return (
     `${year} ${make} ${model}`
   )
 }
 }


export function sortVehicles(a,b) {
  const aDisplay = vehicleIdDisplay(a);
  const bDisplay = vehicleIdDisplay(b);
  if (a.overall < b.overall) {
    return 1; // a has a lower score and should go after b
  } else if (a.overall > b.overall) {
    return -1; // a has a higher score and should go before b
  } else { // When overall scores are equal, sort alphanumerically based on display value
    if (aDisplay < bDisplay) {
        return -1; // aDisplay comes before bDisplay alphanumerically
    } else if (aDisplay > bDisplay) {
        return 1; // aDisplay comes after bDisplay alphanumerically 
    } else {
        return 0; // aDisplay and bDisplay are equal
    }
  }
}

export function calcEffectiveBattery(charge_to_ld,discharge_to_ld,charge_to_mdhd,discharge_to_mdhd,is_ld,usable_capacity) {
  let charge_to = is_ld ? charge_to_ld : charge_to_mdhd;
  let discharge_to = is_ld ? discharge_to_ld : discharge_to_mdhd;
  let capacity_limit_kwh = 100 - charge_to + discharge_to;
  let capacity_limit_pct = (100-capacity_limit_kwh)/100;
  let effective_battery_kwh = usable_capacity * capacity_limit_pct;
  return effective_battery_kwh;
}

export function calculateProjectedSavings(activity) {
  let totalSavings = activity
    .filter((vehicle) => (vehicle.is_ev_recommendation))
    .reduce((previousValue, currentValue) => (previousValue += currentValue.rec_lt_net_savings), 0);
  var resultNegative = false;
  if (totalSavings > 0) {
    resultNegative = true;
  }
  totalSavings = Math.abs(totalSavings)
  if (totalSavings <= 0) {
    totalSavings = 0;
  }
  else if (totalSavings < 10000) {
    totalSavings = Math.round(Math.round(totalSavings / 1000) * 1000);
  }
  else {
    totalSavings = Math.round(Math.round(totalSavings / 10000) * 10000);
  }

  return totalSavings * (resultNegative ? -1 : 1);
}

export function calculateGhgReductionAmt(activity, userSettings) {
  let reduction = Math.round(activity
    .filter((vehicle) => (vehicle.is_ev_recommendation))
    .reduce((previousValue, currentValue) => (previousValue += currentValue.rec_lt_ghg_reduction_lbs), 0))
  return ghgConversionForSummary(userSettings, reduction)
}

// This function currently does not calculate what we want it to. If we decide to proceed with using percentages for the GHG reduction in the PDF, we would need to update the function to use all (selected) vehicles' GHG emissions AMOUNTS as the denominator, or ideally update the logic to use all vehicles' current GHG emissions as the denominator, not just their reductions amount.

// export function calculateGhgReductionPct(activity) {
//   let totalReduction = 0; // Sum of all reductions
//   let totalVehicles = 0; // Total number of vehicles

//   // Iterate through each activity
//   activity.forEach(vehicle => {
//     // Check if the activity item is a vehicle and has the is_ev_recommendation flag
//     if (vehicle.is_ev_recommendation) {
//       // Add its rec_lt_ghg_reduction_pct to totalReduction
//       totalReduction += vehicle.rec_lt_ghg_reduction_pct;
//     }
//     // Increment totalVehicles count for every vehicle
//     totalVehicles++;
//   });

//   // Calculate the average reduction percentage
//   const averageReduction = (totalReduction / (totalVehicles)/100;

//   return averageReduction; // Return the calculated average reduction
// }

export function calculateTotalDistance(activity) {
  return Math.round(activity.reduce((previousValue, currentValue) => (previousValue += (currentValue.obs_km ?? 0)), 0))
}


export const formatFrequency = (frequencyDecimal) => {
  const rawFrequency = frequencyDecimal * 100;
  return rawFrequency > 1 ? Math.round(rawFrequency) : '<1';
}