import React, {useState, useEffect, Suspense} from "react";
import * as S from '../../../styles/core-styles/AdminTools-styles';

const UpdateLocationMap= React.lazy(() => import('./UpdateLocationMap'));

const ALPHANUMERIC_REGEX = "^[\\w\\-\\, ]*$"
const INPUT_ERROR_MESSAGE = "Please use alphanumeric characters. Special characters allowed: _ - , ";


export default function UpdateLocationModal(props) {
    const {setShowUpdateLocationModal, user, locationToUpdate, updateLocationData, updateGroupLocation} = props;

    const [nickname, setNickname] = useState('');
    const [address, setAddress] = useState('');
    const [locationType, setLocationType] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [newLatitude, setNewLatitude] = useState('');
    const [newLongitude, setNewLongitude] = useState('');
    const [locationList, setLocationList] = useState();
    const [newLocError, setNewLocError] = useState(false);
    const [city, setCity] = useState('');
    const [region, setRegion] = useState('');


    useEffect(() => {
        setAddress(locationToUpdate.address);
        setLatitude(locationToUpdate.latitude)
        setLongitude(locationToUpdate.longitude)
        setNickname(locationToUpdate.nickname ? locationToUpdate.nickname : '');
        setLocationType(locationToUpdate.landuse ? locationToUpdate.landuse : '');
        setLocationList(locationToUpdate.location_details);
        setCity(locationToUpdate.city);
        setRegion(locationToUpdate.region);
    },[locationToUpdate])

    function handleSubmit(e) {
        e.preventDefault();
        let data = {
            city: city,
            region: region,
            pkid: locationToUpdate.pkid,
            nickname: nickname,
            address: address,
            landuse: locationType !== '' ? locationType : locationToUpdate.landuse,
            longitude: newLongitude !== '' ? parseFloat(newLongitude) : longitude,
            latitude: newLatitude !== '' ? parseFloat(newLatitude) : latitude,
        }
        
        if(locationToUpdate.location_details) {
          data.location_ids = locationList.map((loc) => {return loc.pkid})
          return updateGroupLocation(data)
        } else {
          return updateLocationData(data);
        }
    }

    function removeLocationFromGroup(id) {
      setLocationList(prevList => prevList.filter((item) => {return item.uuid !== id}));
    }

    return(
        <S.ModalDisplay>
        <S.ModalContainer>
            <S.ModalCloseButton onClick={() => setShowUpdateLocationModal(false)}>Close</S.ModalCloseButton>
            <S.ModalHeader>Edit Location</S.ModalHeader>
            <S.ModalForm onSubmit={(e) => handleSubmit(e)}>
            <S.ModalFormInputWrapper>
                <S.ModalFormInputLabel>Location Nickname:</S.ModalFormInputLabel>
                  <S.ModalFormInput
                    value={nickname}
                    onChange={(e) => setNickname(e.target.value)}
                    pattern={ALPHANUMERIC_REGEX}
                    title={INPUT_ERROR_MESSAGE}
                    type='text'
                    />
            </S.ModalFormInputWrapper>
            
            <S.ModalFormInputWrapper>
                <S.ModalFormInputLabel>Location Type:</S.ModalFormInputLabel>
                <S.ModalFormSelect
                  onChange={(e) => setLocationType(e.target.value)}
                  value={locationType}
                >
                <option>Unclassified</option>
                <option>Fleet</option>
                <option>Public</option>
                <option>Residential</option>
                <option>Workplace</option>
                </S.ModalFormSelect>
              </S.ModalFormInputWrapper>

            <S.ModalFormInputWrapper>
                <S.ModalFormInputLabel>*Address:</S.ModalFormInputLabel>
                  <S.ModalFormInput
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    pattern={ALPHANUMERIC_REGEX}
                    title={INPUT_ERROR_MESSAGE}
                    type='text'
                    required
                />
            </S.ModalFormInputWrapper>

            <S.ModalFormInputWrapper>
                <S.ModalFormInputLabel>*City:</S.ModalFormInputLabel>
                  <S.ModalFormInput
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    pattern={ALPHANUMERIC_REGEX}
                    title={INPUT_ERROR_MESSAGE}
                    type='text'
                    required
                />
            </S.ModalFormInputWrapper>

            <S.ModalFormInputWrapper>
                <S.ModalFormInputLabel>*State/Region:</S.ModalFormInputLabel>
                  <S.ModalFormInput
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                    pattern={ALPHANUMERIC_REGEX}
                    title={INPUT_ERROR_MESSAGE}
                    type='text'
                    required
                />
            </S.ModalFormInputWrapper>

            <S.UpdateLocationMapContainer>
            <S.UpdateLocationMapText>Update Map Location</S.UpdateLocationMapText>
            <S.UpdateLocationMapSubText>Click on the map to select new lat/lon coordinates for this location</S.UpdateLocationMapSubText>
            <S.UpdateLocationMapSubText>New coordinates must be within the blue circle displayed on the map (300 meters)</S.UpdateLocationMapSubText>
            {newLocError &&
            <div style={{color: 'red'}}>Error: New location needs to be within 300 meters of old location.</div>}
                <Suspense fallback={<></>}>
                <UpdateLocationMap 
                    user={user} 
                    locationToUpdate={locationToUpdate}
                    locationList={locationList}
                    removeLocationFromGroup={removeLocationFromGroup}
                    setNewLatitude={setNewLatitude}
                    setNewLongitude={setNewLongitude}
                    setNewLocError={setNewLocError}
                />
                </Suspense>
            </S.UpdateLocationMapContainer>

            <S.ModalFormButtonWrapper>
              <S.ModalSaveButton disabled={newLocError === true} type='submit'>Save</S.ModalSaveButton>
            </S.ModalFormButtonWrapper>
            </S.ModalForm>
        </S.ModalContainer>
      </S.ModalDisplay>
    )
}