import React from 'react';
import * as S from "../../styles/ezev-fx-styles/RatesTable-styles"

//createRateDates, createRateDOW, createRateTOU functions are also used in Pdf report electricy rate table.
export function createRateDates(rateObj) {
  if (rateObj.startMonth == null || rateObj.stopMonth === null || rateObj.startMonth === undefined || rateObj.stopMonth === undefined)
    return "All Selected";
  if (rateObj.startDayOfMonth === null || rateObj.stopDayOfMonth === null || rateObj.startDayOfMonth === undefined || rateObj.stopDayOfMonth === undefined) {
    return `${rateObj.startMonth + " - " + rateObj.stopMonth}`;
  } else {
    return `${humanFriendlyMonth(rateObj.startMonth)} ${(rateObj.startDayOfMonth + 1)} - ${humanFriendlyMonth(rateObj.stopMonth)} ${(rateObj.stopDayOfMonth + 1)}`;
  }
}

export function createRateDOW(rateObj) {
  //   this should be type RateObject - breaks accessing rateObj via string. Need to impliment explicit type casting
  if (rateObj.mondays === null) return "All Selected";
  let selectedDays = [];
  const dowArray = [
    "mondays",
    "tuesdays",
    "wednesdays",
    "thursdays",
    "fridays",
    "saturdays",
    "sundays",
  ];
  dowArray.forEach((d) => {
    if (rateObj[d] === true) {
      d = d.charAt(0).toUpperCase() + d.slice(1, 3);
      selectedDays.push(d);
    }
  });
  if (selectedDays.length > 0) {
    return selectedDays.join(', ');
  } else {
    return "All Selected";
  }
}

function humanFriendlyMonth(mnth) {
  switch (mnth) {
    case 0: return 'January';
    case 1: return 'February';
    case 2: return 'March';
    case 3: return 'April';
    case 4: return 'May';
    case 5: return 'June';
    case 6: return 'July';
    case 7: return 'August';
    case 8: return 'September';
    case 9: return 'October';
    case 10: return 'November';
    case 11: return 'December';
    default: return "-";
  }
}

export function createRateTOU(rateObj) {
  if (rateObj.startHour === null || rateObj.stopHour === null || rateObj.startHour === undefined || rateObj.stopHour === undefined ||
    rateObj.startMinute === null || rateObj.stopMinute === null || rateObj.startMinute === undefined || rateObj.stopMinute === undefined)
    return "All Selected";

  return `${rateObj.startTimestamp} - ${rateObj.stopTimestamp}`;
}

export default function RatesTable({ settings, kwhRates, userSettings}) {
  
  return(
      <>
             <S.AboutTableTitle>
             <h2 className="ezev-h2">Electricity Rates</h2>
          </S.AboutTableTitle>


          {kwhRates.length < 1 &&
            <S.AboutCopy>
              Your fleet's default kWh rate is {userSettings.currency_symbol}{settings.local_kwh_cost}. If you have a seasonal, time-of-use, or other electricity rate to include, please contact us at <S.EmailLink href="mailto:info@sawatchlabs.com">info@sawatchlabs.com</S.EmailLink>.
            </S.AboutCopy>
          }
    {kwhRates.length > 0 &&
        <>
          <S.AboutTable>
            <S.AboutTableHead>
              <S.AboutTableTr>
                <S.AboutTableTh>
                  Rate Name
                </S.AboutTableTh>
                <S.AboutTableTh>
                  Rate
                </S.AboutTableTh>
                <S.AboutTableTh>
                  Date Duration
                </S.AboutTableTh>
                <S.AboutTableTh>
                  Active Days
                </S.AboutTableTh>
                <S.AboutTableTh>
                  Active Times
                </S.AboutTableTh>
              </S.AboutTableTr>
            </S.AboutTableHead>
            <S.AboutTableBody>
              {kwhRates.map((r, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <S.AboutTableTr>
                      <S.AboutTableTd>
                        {r.nickname}
                      </S.AboutTableTd>
                      <S.AboutTableTd data-testid={`row-${idx}-rateAmount-testid`}>
                        {`${userSettings.currency_symbol}${r.kwhRate}`}
                      </S.AboutTableTd>
                      <S.AboutTableTd>
                        {createRateDates(r)}
                      </S.AboutTableTd>
                      <S.AboutTableTd>
                        {createRateDOW(r)}
                      </S.AboutTableTd>
                      <S.AboutTableTd>
                        {createRateTOU(r)}
                      </S.AboutTableTd>
                    </S.AboutTableTr>
                  </React.Fragment>
                )
              })}
            </S.AboutTableBody>
          </S.AboutTable>

          <S.AboutCopy>
            If none of the above rates apply for a given time period, your fleet's default kWh rate of <span data-testid="default-rate-testid">{userSettings.currency_symbol}{settings.local_kwh_cost.toFixed(2)}</span> is used.
          </S.AboutCopy>
  
          <S.AboutCopy>
            For additional information, please reach out to the Sawatch team at <S.EmailLink href="mailto:info@sawatchlabs.com">info@sawatchlabs.com</S.EmailLink>.
          </S.AboutCopy>
        </>
      }</>
  )
}
