import React, { useEffect, useState, Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import HeaderSingleVcl from './HeaderSingleVcl';
import { processApiResponse } from './utils/ConformUnits';
import { calcEffectiveBattery } from './HelperFunctions';
import Loading from './loading';

// code-split imports
const SingleVclPage = React.lazy(() => import("./SingleVclPage"));

const SingleVclController = (props) => {
  const { activity } = props;
  const [vclResults, setVclResults] = useState(null);
  const [candidateResults, setCandidateResults] = useState(null);
  const [vclFuelingData, setVclFuelingData] = useState(null);
  const [vclSummaryData, setVclSummaryData] = useState(null);
  const [transactionCount, setTransactionCount] = useState(null);

  useEffect(() => {
    props.getAnalyticsProcessingState(false);
    getData();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity]); // Trigger data fetching when activity changes

  const getData = () => {
    let vinFromPath = props.match.params.vin;

    // Check if location.state exists; if not, use the VIN from the pathname
    if (!props.location.state && vinFromPath) {
      props.location.state = { vcl: vinFromPath }; // Set location.state manually
    }

    // If still no state or activity, exit the function
    if (!props.location.state || !activity) return;

    // If vehicle data is set, fetch candidate and fueling data
    if (setVclData()) {
      getCandidateData();
      getFuelingData();
    }
  };

  const setVclData = () => {
    let vin = props.location.state.vcl;
    let vcl = activity.find(v => v.vin === vin);

    if (vcl) {
      // Set vehicle results in state
      setVclResults(vcl);
      // Fetch the vehicle's daily activity for the graph
      fetchVclDailyActivity(vcl);
      return true;
    } else {
      console.error("Vehicle not found. Redirecting to landing page...");
      props.history.push('/');
      return false;
    }
  };

  const getCandidateData = () => {
    var vcl = props.location.state.vcl;
    var url = `${props.apiURL}getezevcandidateresults`;
    var queries = `?ident=${props.dbName}&vin=${vcl}`;
    
    // Fetch candidate data from the API
    fetch(`${url}${queries}`, { headers: { 'Authorization': `Bearer ${props.user.token}` } })
      .then(res => res.json())
      .then(data => {
        if (data?.data?.[0]?.json) {
          const json = data.data[0].json;

          // Process BEVs in candidate data
          if (json.bevs) {
            json.bevs.map((d) => {
              let cand = props.candidates.find(c => c.id === d.id);
              if (cand?.upfits) {
                d.upfits = cand.upfits;
              }
              d = processApiResponse(props.user.userSettings, d);
              return null;
            });
          }

          // Process PHEVs in candidate data
          if (json.phevs) {
            json.phevs.map((d) => {
              let cand = props.candidates.find(c => c.id === d.id);
              if (cand?.upfits) {
                d.upfits = cand.upfits;
              }
              d = processApiResponse(props.user.userSettings, d);
              return null;
            });
          }
          // Set candidate results in state
          setCandidateResults(json);
        }
      })
      .catch(error => console.error("Error: " + error));
  };

  const getFuelingData = () => {
    if (!props.location.state) return;
    var vin = props.location.state.vcl;
    const vehicleFuelingData = props.fuelingData[vin] ?? [];
    const transactionCount = props.vehicleTransactionCounts[vin] ?? 0;

    // Set fueling data for the vehicle
    setVclFuelingData(vehicleFuelingData);
    setTransactionCount(transactionCount);
  };

  const fetchVclDailyActivity = (vcl) => {
    let url = props.apiURL;
    let endDate = new Date(vcl.observed_max_ts.date).toISOString().split("T")[0];
    let startDate = new Date(vcl.observed_min_ts.date).toISOString().split("T")[0];
    var singleVclUrl = `${url}getEnergyGraphData?`;
    var queries = `clientId=${props.dbName}&vin=${vcl.vin}&start=${startDate}&stop=${endDate}`;

    // Fetch daily vehicle activity data for graph rendering
    fetch(`${singleVclUrl}${queries}`, {
      headers: { Authorization: `Bearer ${props.user.token}` },
    })
      .then((res) => res.json())
      .then((data) => {
        // Sort the data and group it by month/year
        data.data.sort((a, b) => (a.localized_date > b.localized_date ? 1 : -1));
        let monthlyGroupedData = {};
        data.data.map((d) => {
          let month = d.localized_date.split('T')[0].split('-')[1];
          let year = d.localized_date.split('T')[0].split('-')[0].slice(2);
          let monthYear = `${month}/${year}`;
          d.localized_date = d.localized_date.replace("Z", "");

          // Group daily activity by month/year
          if (monthlyGroupedData[monthYear]) {
            monthlyGroupedData[monthYear].push(d);
          } else {
            monthlyGroupedData[monthYear] = [d];
          }
          return null;
        });
        setVclSummaryData(monthlyGroupedData);
      })
      .catch((error) => console.error("Error: " + error));
  };

  const getScoredOnExplanation = (rec, scored_on, is_ld) => {
    let vehicleCategory = is_ld === true ? 'Light Duty' : 'Medium and Heavy Duty';

    // Generate the explanation for vehicle scoring based on recommendation
    if (rec === "No Change") {
      return `This vehicle was compared to all available ${vehicleCategory} EVs, none of which were a good economic and operational fit. These scores are based on the EV that was the best fit: ${scored_on}.`;
    } else if (rec === "Possible Sedan Fit") {
      return `This vehicle was compared to all available EVs in its class, none of which were a good economic and operational fit. It was then compared against electric sedans and was identified to be a good fit for an electric sedan. These scores are based on the electric sedan model that was the best fit: ${scored_on}.`;
    } else if (rec === "Operational Fit") {
      return `This vehicle was compared to all available ${vehicleCategory} EVs, none of which were a good economic fit. However, at least one vehicle was a good operational fit. These scores are based on the EV that was the best operational fit: ${scored_on}.`;
    } else if (rec === 'No Models Fit') {
      return `There are no EV candidates within this vehicle class. These scores are based on the EV that was the best fit: ${scored_on}.`;
    } else {
      return '';
    }
  };

  let component;
  if (!vclResults || !candidateResults || !vclFuelingData) {
    // Display a loading state if data hasn't been fetched yet
    component = (
      <>
        <HeaderSingleVcl vcl={{ asset_id: '' }} />
        <div className="ezev-loading-wrapper">
          <Loading />
        </div>
      </>
    );
  } else {
    // Generate the explanation text for scored on data
    let explanation = getScoredOnExplanation(vclResults.recommendation, vclResults.scored_on, vclResults.is_ld);

    // Combine and map candidate results to include PHEVs and BEVs
    let candResults = [
      ...candidateResults.phevs.map(item => ({ ...item, is_phev: true, is_bev: false })),
      ...candidateResults.bevs.map(item => ({ ...item, is_phev: false, is_bev: true }))
    ];

    // Handle ICE candidates and map battery data
    let newIce = candidateResults.ice_comp;
    candResults.map(c => {
      //legacy dbs don't have an id on the candidates table and use ymm for uniqueness.
      //so we fall back to ymm on falsy values for cand.
      let cand = props.candidates.find(e => e.id === c.id) || props.candidates.find(e => e.ymm === c.ymm);
      // check for the rare case where a candidate was updated but analytics was not yet rerun
      if (!cand) return (c.effective_battery_kwh = '-');
      if (cand) c.price = cand.net_price;
      c.usable_kwh = cand.usable_kwh !== undefined && cand.usable_kwh !== null ? Math.round(cand.usable_kwh * 10) / 10 : '-';
      if (cand.usable_kwh !== undefined && cand.usable_kwh !== null) {
        return c.effective_battery_kwh = calcEffectiveBattery(
          props.settings.charge_to_ld,
          props.settings.discharge_to_ld,
          props.settings.charge_to_mdhd,
          props.settings.discharge_to_mdhd,
          vclResults.is_ld,
          cand.usable_kwh
        );
      } else {
        return c.effective_battery_kwh = '-';
      }
    });

    component = (
      <SingleVclPage
        vcl={vclResults}
        scoredOnText={explanation}
        localKwh={props.localKwh}
        fueling={vclFuelingData}
        cands={candResults}
        newIce={newIce}
        settings={props.settings}
        userSettings={props.user.userSettings}
        dbDisplayName={props.dbDisplayName}
        apiURL={props.apiURL}
        user={props.user}
        graphData={vclSummaryData}
        products={props.products}
        transactionCount={transactionCount}
      />
    );
  }

  return (
    <div>
      <Suspense fallback={<HeaderSingleVcl vcl={{ asset_id: '' }} />}>
        {component}
      </Suspense>
    </div>
  );
};

export default withRouter(SingleVclController);